import { AuthState } from '../types/States';
import {
    AuthUserAction,
    AuthUserOnboardingStepAction,
    AuthUserPlanRegistrationAction,
    AuthUserPlanSelectionAction, AuthUserSCConnectAction, AuthUserSourcedAccountsAction,
    ReduxAction
} from '../types/ReduxActions'
import {
    AUTH_USER,
    AUTH_USER_OB_STEP,
    AUTH_USER_PLAN_REGISTRATION,
    AUTH_USER_PLAN_SELECTION,
    AUTH_USER_SOURCED_ACCOUNTS,
    AUTH_USER_SC_CONNECT,
} from '../actions/types';
import { CurrentUser } from '../types/Users';
import {Timestamp} from 'firebase/firestore'

const INITIAL_STATE: AuthState = {
    currentUser: null,
};

export default function authReducer(state = INITIAL_STATE, action: ReduxAction) {
    switch(action.type) {
        case AUTH_USER: {
            const authUserAction: AuthUserAction = action;
            return {...state, currentUser: authUserAction.payload};
        }
        case AUTH_USER_OB_STEP: {
            const onboardingStepAction: AuthUserOnboardingStepAction = action;
            const {currentUser} = state;
            if (!currentUser) {
                return state;
            }
            return {
                ...state,
                currentUser: {
                    ...currentUser,
                    onboardingStep: onboardingStepAction.payload,
                }
            };
        }
        case AUTH_USER_PLAN_REGISTRATION: {
            const planRegistrationAction: AuthUserPlanRegistrationAction = action;
            const { currentUser } = state;
            return {
                ...state,
                currentUser: {
                    ...currentUser,
                    subscription: planRegistrationAction.payload,
                }
            }
        }
        case AUTH_USER_PLAN_SELECTION: {
            const planSelectionAction: AuthUserPlanSelectionAction = action;
            const { currentUser } = state;
            return {
                ...state,
                currentUser: {
                    ...currentUser,
                    selectedPlan: planSelectionAction.payload,
                }
            }
        }
        case AUTH_USER_SOURCED_ACCOUNTS: {
            const sourcedAccountsAction: AuthUserSourcedAccountsAction = action;
            const { currentUser } = state;
            if (!currentUser) {
                return state;
            }
            const updatedUser: CurrentUser = {
                ...currentUser,
                numSourceRequests: currentUser.numSourceRequests + 1,
                numSourcedAccounts: currentUser.numSourcedAccounts + sourcedAccountsAction.payload.numSourcedAccounts,
                lastSourceRequest: Timestamp.fromDate(sourcedAccountsAction.payload.lastSourceRequest),
            };
            return {
                ...state,
                currentUser: updatedUser,
            }
        }

        case AUTH_USER_SC_CONNECT: {
            const scConnectAction: AuthUserSCConnectAction = action;
            const { currentUser } = state;
            return {
                ...state,
                currentUser: {
                    ...currentUser,
                    isSCAuthed: scConnectAction.payload,
                }
            }
        }
        default:
            return state;
    }
}
