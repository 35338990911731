export enum DiscountCodeValidationResult {
  NO_OFFER_INVALID_DISCOUNT_CODE = 'NO_OFFER_INVALID_DISCOUNT_CODE',
  NO_OFFER_ONLY_FIRST_TIME_USER = 'NO_OFFER_ONLY_FIRST_TIME_USER',
  NO_OFFER_ON_MONTHLY_PLANS = 'NO_OFFER_ON_MONTHLY_PLANS',
  NO_OFFER_ON_YEARLY_PLANS = 'NO_OFFER_ON_YEARLY_PLANS',
  THIRTY_PERCENT_OFF_1_MONTH = 'THIRTY_PERCENT_OFF_1_MONTH',
  HUNDRED_PERCENT_OFF_1_MONTH = 'HUNDRED_PERCENT_OFF_1_MONTH',
  FIFTY_PERCENT_OFF_1_MONTH = 'FIFTY_PERCENT_OFF_1_MONTH',
  FIFTY_PERCENT_OFF_6_MONTHS = 'FIFTY_PERCENT_OFF_6_MONTHS',
  TEN_PERCENT_OFF_1_MONTH = 'TEN_PERCENT_OFF_1_MONTH',
  SEVEN_DAY_FREE_TRIAL = 'SEVEN_DAY_FREE_TRIAL',
}

export type SuccessfulDiscountCodeValidationResult = Exclude<DiscountCodeValidationResult, DiscountCodeValidationResult.NO_OFFER_INVALID_DISCOUNT_CODE | DiscountCodeValidationResult.NO_OFFER_ONLY_FIRST_TIME_USER | DiscountCodeValidationResult.NO_OFFER_ON_YEARLY_PLANS | DiscountCodeValidationResult.NO_OFFER_ON_MONTHLY_PLANS>

export type ValidateDiscountCodeResponse = {
  validationResult: DiscountCodeValidationResult,
  referrerScId?: string,
}
