export const AUTH_USER = 'auth_user';
export const AUTH_USER_OB_STEP = 'auth_user_ob_step';
export const AUTH_USER_PLAN_REGISTRATION = 'auth_user_plan_registration';
export const AUTH_USER_PLAN_SELECTION = 'auth_user_plan_selection';
export const AUTH_USER_SOURCED_ACCOUNTS = 'auth_user_sourced_accounts';
export const AUTH_USER_SC_CONNECT = 'auth_user_sc_connect';
export const LOADING = 'loading';
export const FLASH_SUCCESS = 'flash_success';
export const FLASH_ERROR = 'flash_error';
export const ANALYTICS_BETRAYERS = 'analytics_betrayers';
export const ANALYTICS_FOLLOW_BACK_USERS = 'analytics_follow_back_users';
export const ANALYTICS_NON_FOLLOW_BACK_USERS = 'analytics_non_follow_back_users';
export const ANALYTICS_TO_FOLLOW_USERS = 'analytics_to_follow_users';
export const ANALYTICS_ONE_WAY_FOLLOWS = 'analytics_one_way_follows';
export const ANALYTICS_RECOMMENDED_SOURCES = 'analytics_recommended_sources';
export const ANALYTICS_JUICY_SOURCES = 'analytics_juicy_sources';
export const ANALYTICS_FOLLOWER_COUNTS = 'analytics_follower_counts';
export const ANALYTICS_IS_FOLLOWING_ENABLED = 'analytics_is_following_enabled';
export const ANALYTICS_UNFOLLOW_ONE_WAY_FOLLOWS = 'analytics_unfollow_one_way_follows';
export const ANALYTICS_AUTOMATIC_SCHEDULING = 'analytics_automatic_scheduling';
export const REFERRAL_REFERRALS = 'referral_referrals'