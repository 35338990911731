import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";
import {db} from '../common/firebase';
import {CombinedUserData, OnboardingStep, SelectedPlanType, SoundCloudProfile, SubscriptionType,} from '../types/Users';
import {
  Betrayer,
  FollowerCount,
  FollowingUser,
  JuicySource,
  OneWayFollow,
  RawFollowerCount,
  RawJuicySource,
  RawRecommendedSource,
  RecommendedSource,
  ToFollowUser,
} from '../types/SoundCloudUsers';
import {Referral} from '../types/Referral'

export const usersPath = 'follow_freak/app/users';
export const soundcloudUsersPath = 'follow_freak/app/platforms/soundcloud/users';
const TIMESTAMP_KEY = 'timestamp';
const SUBSCRIPTION_KEY = 'subscription';
const SELECTED_PLAN_KEY = 'selectedPlan';
const FOLLOWS_BACK_KEY = 'follows_back';
const ONBOARDING_STEP_KEY = 'onboardingStep';
const IS_SC_AUTHED_KEY = 'isSCAuthed';
const NUM_SOURCE_REQUESTS = 'numSourceRequests';
const NUM_SOURCED_ACCOUNTS = 'numSourcedAccounts';
const LAST_SOURCE_REQUEST = 'lastSourceRequest';
const LAST_LOGIN = 'lastLogin';
const SUBSCRIPTION_CYCLE_END = 'subscriptionCycleEnd';
const FOLLOWER_COUNTS = 'followerCounts';
const JUICY_SOURCES = 'juicySources';
const RECOMMENDED_SOURCES = 'recommendedSources';
const ONE_WAY_FOLLOWS = 'wastedFollowings';
const IS_FOLLOWING_ENABLED = 'isEnabled';
const UNFOLLOW_ONE_WAY_FOLLOWS = 'unfollowWastedFollowings';
const AUTOMATIC_SCHEDULING = 'automaticScheduling';
const PURCHASED_AT = 'purchasedAt'

export const subscribeUserToPlan = async (ffUserId: string, scId: string, plan: SubscriptionType) => {
  if (!plan) {
    throw new Error('Plan not specified');
  }
  const ffUserData = {
    [SUBSCRIPTION_KEY]: plan,
    [SELECTED_PLAN_KEY]: plan,
    [ONBOARDING_STEP_KEY]: 1,
  };

  const userDocRef = doc(db, usersPath, ffUserId);
  await updateDoc(userDocRef, ffUserData);

  const scUserData = {
    [SUBSCRIPTION_KEY]: plan,
  };
  const scUserDocRef = doc(db, soundcloudUsersPath, scId)
  await updateDoc(scUserDocRef, scUserData);

  return Promise.resolve();
};

export const updateSelectedPlan = async (userId: string, plan: SelectedPlanType) => {
  const selectedPlanData = {
    [SELECTED_PLAN_KEY]: plan,
  };
  const docRef = doc(db, usersPath, userId);
  await updateDoc(docRef, selectedPlanData)
  return Promise.resolve();
};

export const updateRefreshableFields = async (userId: string, scProfile: SoundCloudProfile, lastLogin: Date, subscriptionCycleEnd: Date | null) => {
  const docRef = doc(db, usersPath, userId);
  await updateDoc(docRef, {
    ...scProfile,
    [LAST_LOGIN]: lastLogin,
    [SUBSCRIPTION_CYCLE_END]: subscriptionCycleEnd,
  });

  return Promise.resolve();
};

export const setIsSCAuthed = async (userId: string, isSCAuthed: boolean) => {
  const isSCAuthedData = {
    [IS_SC_AUTHED_KEY]: isSCAuthed,
  };
  const docRef = doc(db, usersPath, userId);
  await updateDoc(docRef, isSCAuthedData);
  return Promise.resolve();
};

export const updateOnboardingStep = async (userId: string, step: OnboardingStep) => {
  const onboardingStepData = {
    [ONBOARDING_STEP_KEY]: step,
  };
  const docRef = doc(db, usersPath, userId);
  await updateDoc(docRef, onboardingStepData);
  return Promise.resolve();
};

export const updateSourcedAccounts = async (userId: string, numSourcedAccounts: number, lastSourcRequest: Date) => {
  const sourcedAccountsData = {
    [NUM_SOURCE_REQUESTS]: increment(1),
    [NUM_SOURCED_ACCOUNTS]: increment(numSourcedAccounts),
    [LAST_SOURCE_REQUEST]: lastSourcRequest,
  };
  const docRef = doc(db, usersPath, userId);
  await updateDoc(docRef, sourcedAccountsData);
  return Promise.resolve();
};

export const getCombinedUserData = async (userId: string): Promise<CombinedUserData> => {
  const docRef = doc(db, usersPath, userId);
  const documentSnapshot  = await getDoc(docRef);
  if (documentSnapshot && documentSnapshot.exists()) {
    const documentData = documentSnapshot.data();
    if (!documentData) {
      const error = new Error('The user data is empty!');
      return Promise.reject(error);
    }
    const combinedUserData = documentData as CombinedUserData;
    combinedUserData.subscriptionCycleEnd = combinedUserData.subscriptionCycleEnd ?? null;
    combinedUserData.lastSourceRequest = combinedUserData.lastSourceRequest ?? null;
    combinedUserData.numReferrals = combinedUserData.numReferrals ?? 0;
    combinedUserData.promoterInitiatedAt = combinedUserData.promoterInitiatedAt ?? null;

    return Promise.resolve(combinedUserData);
  } else {
    const error = new Error('The user data could not be found');
    return Promise.reject(error);
  }
};

export const getToFollowQueue = async (scId: string): Promise<ToFollowUser[]> => {
  const rawUsersQuery = query(
    collection(db, `${soundcloudUsersPath}/${scId}/toFollowQueue`),
    orderBy(TIMESTAMP_KEY)
  );
  const rawUsers = await getDocs(rawUsersQuery);
  if (rawUsers.empty) {
    return Promise.resolve([])
  } else {
    const toFollowUsers: ToFollowUser[] = rawUsers.docs.map(doc => {
      const data = doc.data();
      const { username, photoURL, timestamp } = data;

      return {
        id: doc.id,
        username,
        photoURL,
        timestamp
      };
    });
    return Promise.resolve(toFollowUsers);
  }
};

export const getEligibleReferrals = async (scId: string, promoterInitiatedAt: Timestamp): Promise<Referral[]> => {
  const eligibleReferralsQuery = query(
    collection(db, `${soundcloudUsersPath}/${scId}/referrals`),
    where(PURCHASED_AT, '>', promoterInitiatedAt),
    orderBy(PURCHASED_AT, 'desc')
  )
  const rawReferrals = await getDocs(eligibleReferralsQuery)
  if (rawReferrals.empty) {
    return Promise.resolve([])
  }
  const referrals: Referral[] = rawReferrals.docs.map(doc => {
    const data = doc.data();
    const { payoutAmountUSD, plan, purchasedAt, paidOutAt, scUsername } = data;
    return {
      id: doc.id,
      paidOutAt,
      payoutAmountUSD,
      plan,
      purchasedAt,
      scUsername,
    }
  })
  return Promise.resolve(referrals);
}

export const getFollowBacks = async (scId: string): Promise<FollowingUser[]> => {
  const rawUsersQuery = query(
    collection(db, `${soundcloudUsersPath}/${scId}/following`),
    where(FOLLOWS_BACK_KEY, '==', true),
    orderBy(TIMESTAMP_KEY)
  );
  const rawUsers = await getDocs(rawUsersQuery);
  if (rawUsers.empty) {
    return Promise.resolve([])
  } else {
    const followBackUsers: FollowingUser[] = rawUsers.docs.map(doc => {
      const data = doc.data();
      const { username, photoURL, timestamp, follows_back } = data;

      return {
        id: doc.id,
        username,
        photoURL,
        timestamp,
        follows_back,
      };
    });

    return Promise.resolve(followBackUsers);
  }
};

export const getOldFollowBacks = async (scId: string): Promise<FollowingUser[]> => {
  const rawUsersQuery = query(
    collection(db, `${soundcloudUsersPath}/${scId}/blacklist`),
    where(FOLLOWS_BACK_KEY, '==', true),
    orderBy(TIMESTAMP_KEY)
  );
  const rawUsers = await getDocs(rawUsersQuery);
  if (rawUsers.empty) {
    return Promise.resolve([])
  } else {
    const followBackUsers: FollowingUser[] = rawUsers.docs.map(doc => {
      const data = doc.data();
      const { username, photoURL, timestamp, follows_back } = data;

      return {
        id: doc.id,
        username,
        photoURL,
        timestamp,
        follows_back,
      };
    });

    return Promise.resolve(followBackUsers);
  }
};

export const getNonFollowBacks = async (scId: string): Promise<FollowingUser[]> => {
  const rawUsersQuery = query(
    collection(db, `${soundcloudUsersPath}/${scId}/following`),
    where(FOLLOWS_BACK_KEY, '==', false),
    orderBy(TIMESTAMP_KEY)
  );
  const rawUsers = await getDocs(rawUsersQuery);
  if (rawUsers.empty) {
    return Promise.resolve([])
  } else {
    const nonFollowBackUsers: FollowingUser[] = rawUsers.docs.map(doc => {
      const data = doc.data();
      const { username, photoURL, timestamp, follows_back } = data;

      return {
        id: doc.id,
        username,
        photoURL,
        timestamp,
        follows_back,
      };
    });

    return Promise.resolve(nonFollowBackUsers);
  }
};

export const getBetrayers = async (scId: string): Promise<Betrayer[]> => {
  const rawUsersQuery = query(collection(db, `${soundcloudUsersPath}/${scId}/betrayers`), orderBy(TIMESTAMP_KEY));
  const rawUsers = await getDocs(rawUsersQuery);
  if (rawUsers.empty) {
    return Promise.resolve([])
  } else {
    const betrayers: Betrayer[] = rawUsers.docs.map(doc => {
      const data: any = doc.data();
      const { username, photoURL, timestamp, follows_back } = data;

      return {
        id: doc.id,
        username,
        photoURL,
        timestamp,
        follows_back,
      };
    });

    return Promise.resolve(betrayers);
  }
};

export const getJuicySources = (documentData: any): JuicySource[] => {
  const rawJuicySources: RawJuicySource[] = documentData[JUICY_SOURCES] ?? []
  return rawJuicySources.map((rawJuicySource) => ({
    ...rawJuicySource,
    lastSourced: new Date(rawJuicySource.lastSourced),
  }))
}

export const getFollowerCounts = (documentData: any): FollowerCount[] => {
  const rawFollowerCounts: RawFollowerCount[] = documentData[FOLLOWER_COUNTS] ?? []
  return rawFollowerCounts.map((rawFollowerCount) => ({
    timestamp: new Date(rawFollowerCount.timestamp),
    followerCount: rawFollowerCount.followerCount,
  }))
}

export const getSoundCloudUserDoc = (scId: string)=> {
  const userDoc = doc(db, soundcloudUsersPath, scId);
  return userDoc
}

export const getIsFollowingEnabled = (documentData: any): boolean => {
  if (documentData[IS_FOLLOWING_ENABLED] === undefined) {
    return false
  }
  return documentData[IS_FOLLOWING_ENABLED] as boolean
}

export const setIsFollowingEnabled = async (scId: string, isFollowingEnabled: boolean) => {
  const isFollowingEnabledData = {
    [IS_FOLLOWING_ENABLED]: isFollowingEnabled,
  };
  const docRef = doc(db, soundcloudUsersPath, scId);
  await updateDoc(docRef, isFollowingEnabledData);
};

export const getUnfollowOneWayFollows = (documentData: any): boolean => {
  if (documentData[UNFOLLOW_ONE_WAY_FOLLOWS] === undefined) {
    return false
  }
  return documentData[UNFOLLOW_ONE_WAY_FOLLOWS] as boolean
}

export const setUnfollowOneWayFollows = async (scId: string, unfollowOneWayFollows: boolean) => {
  const unfollowOneWayFollowsData = {
    [UNFOLLOW_ONE_WAY_FOLLOWS]: unfollowOneWayFollows
  }
  const docRef = doc(db, soundcloudUsersPath, scId)
  await updateDoc(docRef, unfollowOneWayFollowsData)
}

export const getAutomaticScheduling = (documentData: any): boolean => {
  if (documentData[AUTOMATIC_SCHEDULING] === undefined) {
    return false
  }
  return documentData[AUTOMATIC_SCHEDULING] as boolean
}

export const getRecommendedSources = (documentData: any): RecommendedSource[] => {
  const rawRecommendedSources: RawRecommendedSource[] = documentData[RECOMMENDED_SOURCES] ?? []
  return rawRecommendedSources.map((rawRecommendedSource) => ({
    timestamp: new Date(rawRecommendedSource.timestamp),
    url: rawRecommendedSource.url,
  }))
}

export const getOneWayFollows = (documentData: any): OneWayFollow[] => {
  return documentData[ONE_WAY_FOLLOWS] ?? []
}

export const setAutomaticScheduling = async (scId: string, automaticScheduling: boolean) => {
  const automaticSchedulingData = {
    [AUTOMATIC_SCHEDULING]: automaticScheduling
  }
  const docRef = doc(db, soundcloudUsersPath, scId)
  await updateDoc(docRef, automaticSchedulingData)
}