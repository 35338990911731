import { ReferralState } from '../types/States'
import {ReduxAction, ReferralReferralsAction} from '../types/ReduxActions'
import {
  REFERRAL_REFERRALS
} from '../actions/types'

const INITIAL_STATE: ReferralState = {
  referrals: [],
}

export default function referralsReducer(state = INITIAL_STATE, action: ReduxAction): ReferralState {
  switch(action.type) {
    case REFERRAL_REFERRALS: {
      const referralReferralsAction: ReferralReferralsAction = action
      return {
        ...state,
        referrals: referralReferralsAction.payload,
      }
    }
    default: {
      return state
    }
  }
}