export const PERSISTENCE_SESSION = 'session';
export const plans = {
    BASIC: {
        name: 'Artist Management Basic',
        unitPriceUSD: 15,
        fiftyOffUnitPriceUSD: 7.50,
        thirtyOffUnitPriceUSD: 10.50,
        tenOffUnitPriceUSD: 13.50,
    },
    GOLD: {
        name: 'Artist Management Gold',
        unitPriceUSD: 25,
        fiftyOffUnitPriceUSD: 12.50,
        thirtyOffUnitPriceUSD: 17.50,
        tenOffUnitPriceUSD: 22.50,
    },
    GOLD_YEARLY: {
        name: 'Artist Management Gold',
        unitPriceUSD: 180,
        fiftyOffUnitPriceUSD: 90,
        thirtyOffUnitPriceUSD: 126,
        tenOffUnitPriceUSD: 162,
    },
    PLATINUM: {
        name: 'Artist Management Platinum',
        unitPriceUSD: 35,
        fiftyOffUnitPriceUSD: 17.50,
        thirtyOffUnitPriceUSD: 24.50,
        tenOffUnitPriceUSD: 31.50,
    },
    PLATINUM_YEARLY: {
        name: 'Artist Management Platinum',
        unitPriceUSD: 252,
        fiftyOffUnitPriceUSD: 126,
        thirtyOffUnitPriceUSD: 176.40,
        tenOffUnitPriceUSD: 226.80,
    },
};
const env = process.env.NODE_ENV;

const StripePublishableKeys = {
    development: 'pk_test_4p6z2Lyuf7nwAt4OgOk4ncUI00xYTfscTg',
    test: 'pk_test_4p6z2Lyuf7nwAt4OgOk4ncUI00xYTfscTg',
    production: 'pk_live_Z9bl3UeeUS5JiFXS31ayQ2j500AvpfWsYD',
};
export const StripePublishableKey = StripePublishableKeys[env];

export const urlTypeTrack = 'trackURL';
export const urlTypeUser = 'userURL';
export const urlTypeOnSoundCloud = 'onSoundCloudURL';

export const AnalyticsMethodEmailAndPassword = 'email_and_password';
export const AnalyticsMethodCustomToken = 'custom_token';

export const AnalyticsUserPropertySubscriptionKey = 'subscription';
export const AnalyticsUserPropertySubscriptionNone = 'NONE';

export const FeatureFlagIsPrivateAccess = false;
export const PrivateAccessPasswordKey = 'ffPrivateAccessPassword';
export const PrivateAccessPassword = 'Cartwheel20';

export const SCClientId = '98351760d6a64473770e7d2de34d0ee2';
export const SCRedirectURI = 'https://artistmgmt.org/auth/soundcloud/callback&response_type=code';

const ApiUrls = {
    development: 'http://localhost:5001/followfreak-2020/us-central1/followfreakApp',
    test: 'http://localhost:5001/followfreak-2020/us-central1/followfreakApp',
    production: 'https://us-central1-followfreak-2020.cloudfunctions.net/followfreakApp',
};
export const ApiUrl = ApiUrls[env];

const AmpKeys = {
    development: '72f56831c9f8fe107e7470b7d0b03d6b',
    test: '72f56831c9f8fe107e7470b7d0b03d6b',
    production: '6cc97f42031c4057eed2aa7226a3a17e',
};
export const AmpKey = AmpKeys[env];
export const LoadingCopy = `Please stick around while our algorithms are working. It may take anywhere between 1 and 9
 minutes to create the perfect list for you. We recommend keeping this tab open. Feel free to do something else in the meantime.`;

export const AmpPageStemMasteringWorkshopForm = 'stem mastering workshop form page'
export const AmpPageStemMasteringWorkshopReplay = 'stem mastering workshop replay page'
export const AmpPageStemMasteringThankYou = 'stem mastering thank you page'
export const AmpPageAdminLogin = 'admin login page'
export const AmpPageHome = 'home page';
export const AmpPageSignup = 'signup page';
export const AmpPageSignin = 'signin page';
export const AmpPageHowItWorks = 'howitworks page';
export const AmpPageInfluencerPayoutPolicy = 'influencer payout policy page';
export const AmpPagePromoterPayoutPolicy = 'promoter payout policy page';
export const AmpPageReferralPayouts = 'referral payouts page';

export const AmpPagePlanSelection = 'plan selection page';
export const AmpPageSCAuth = 'soundcloud auth page';
export const AmpPagePayment = 'payment page';
export const AmpPageAnalyticsDashboard = 'dashboard page';
export const AmpPageIntroAnalyticsDashboard = 'intro dashboard page';

export const AmpPropButtonCTA = 'buttonCTA';
export const AmpPropSeedUrl = 'seedUrl';

export const AmpElementSignupCTA = 'signup CTA';
export const AmpElementScheduleFollowsButton = 'schedule follows button';
export const AmpElementSignupButton = 'signup button';
export const AmpElementStemMasteringReplaySubmitButton = 'stemMastering replay submit button';
export const AmpElementStemMassteringSignUpButton = 'stemMastering sign up button'
export const AmpElementSigninButton = 'signin button';
export const AmpElementSCAuthButton = 'soundcloud login button';
export const AmpElementSubmitPaymentButton = 'submit payment button';
export const AmpElementSCPasswordResetLink = 'soundcloud password reset link';

export const StemMasteringWorkshopDateString = 'August 8, 2024'