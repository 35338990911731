import {AuthState, LoadingState, RootState} from './types/States'
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react'
import * as analyticsHelper from './common/analyticsHelper'
import * as keys from './common/keys'
import { adminLogin } from './actions'
import {bindActionCreators, compose, Dispatch} from 'redux'
import {connect} from 'react-redux'
import {Redirect, useHistory} from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'
import Button from './Button'
import LoadingBars from './components/LoadingBars'
import heroImg from "./images/fanphoto.png"
import {useIsMounted} from './hooks/useIsMounted'


type ArtistEligibilityFormProps = {
  isLoading: LoadingState["isLoading"],
  currentUser: AuthState['currentUser']
  adminLogin: typeof adminLogin,
}

const StyledContainer = styled.div<{$backgroundUrl: string}>`
   text-align: center;
    padding: 50px 0.5em;
    background-size: cover;
    background-position: 50% 30%;
    background-image: ${({ $backgroundUrl }) => `linear-gradient(rgba(247, 191, 77, 0.870588), rgba(247, 191, 77, 0.870588)), url(${$backgroundUrl})`};

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 0px 0px 9px 0;
        font-weight: 600;
        color: ${props => props.theme.colors.white};
        font-size: 2.5em;
        line-height: 1.25em;
    }

    p {
        margin: 0px 0px 36px 0;
        font-size: 1.2em;
        font-weight: 400;
        padding: 0 15px;
        color: ${props => props.theme.colors.white};
        ${media.greaterThan("medium")`
          font-size: 1.5em;
        `}
    }

    ${media.greaterThan("medium")`
      padding: 110px 0;
    `}
`;

const StyledContent = styled.div`
  max-width: 1020px;
  margin: auto;
  display: flex;
  justify-content: center;
`;

const StyledForm = styled.form`
  ${media.greaterThan("medium")`
    max-width: 82%;
  `}
  ${media.greaterThan("large")`
    max-width: 65%;
  `}
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const StyledText = styled.p`
  margin: 0 0 18px 0;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  margin: 0 0 0.8125em 0;
  padding: 1em;
  width: 100%;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 18px;
  background-color: white;
  min-width: 0;
`;

const StyledInputValidationText = styled.div`
  font-size: 16px;
  font-family: Gibson;
  color: ${props => props.theme.colors.black};
  line-height: 1em;
  margin: 0 0 0.8125em 0;
  background-color: #e6ebf190;
  backdrop-filter: blur(10px);
`;

const StyledButton = styled(Button)`
  display: block;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  width: 100%;
`;

const AdminLoginForm: FC<ArtistEligibilityFormProps> = (props) => {
  const history = useHistory();
  const checkIsMounted = useIsMounted()
  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageAdminLogin);
    window.scrollTo(0, 0);
  }, []);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [usernameValidationMessage, setUsernameValidationMessage] = useState('* Username cannot be blank.');
  const [shouldShowUsernameValidationMessage, setShouldShowUsernameValidationMessage] = useState(false);

  const [passwordValidationMessage, setPasswordValidationMessage] = useState('* Password cannot be blank.');
  const [shouldShowPasswordValidationMessage, setShouldShowPasswordValidationMessage] = useState(false);
  const isMounted = checkIsMounted()


  const handleUsernameChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setUsername(evt.target.value);
    if (!evt.target.value) {
      setUsernameValidationMessage('* Username cannot be blank.');
    } else {
      setUsernameValidationMessage('');
      setShouldShowUsernameValidationMessage(false);
    }
  };

  const handlePasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
    if (!evt.target.value) {
      setPasswordValidationMessage('* Password cannot be blank.');
    } else {
      setPasswordValidationMessage('');
      setShouldShowPasswordValidationMessage(false);
    }
  };

  const inputsAreValid = () => {
    const isUsernameValid = username.length > 0;
    const isPasswordValid = password.length > 0;

    if (!isUsernameValid) {
      setShouldShowUsernameValidationMessage(true);
    }
    if (!isPasswordValid) {
      setShouldShowPasswordValidationMessage(true);
    }

    return isUsernameValid && isPasswordValid;
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    analyticsHelper.recordClick(keys.AmpElementSigninButton);
    if (inputsAreValid()) {
      props.adminLogin({
        username,
        password,
      }, () => {
        history.push('/dashboard');
      });
    }
  };

  if (props.currentUser) {
    return <Redirect to='/dashboard' />
  }

  if (props.isLoading) {
    return (
      <LoadingBars caption={isMounted ? 'Verifying your admin credentials...' : undefined}/>
    )
  }

  return (
    <StyledContainer $backgroundUrl={heroImg}>
      <StyledContent>
        <StyledForm onSubmit={handleSubmit}>
          <StyledHeading>Are you an Admin? <br/>Enter your credentials to log in:</StyledHeading>
          <StyledInput
            type="text"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Username"
          />
          {
            shouldShowUsernameValidationMessage &&
              <StyledInputValidationText>
                {usernameValidationMessage}
              </StyledInputValidationText>
          }
          <StyledInput
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
          />
          {
            shouldShowPasswordValidationMessage &&
              <StyledInputValidationText>
                {passwordValidationMessage}
              </StyledInputValidationText>
          }
          <StyledButton
            disabled={props.isLoading}
          >
            Submit
          </StyledButton>
          <StyledText>
            Artist Management has been helping thousands of artists to organically push their music to new listeners each day.
          </StyledText>
          <StyledText>
            We expose your music to 1200 new listeners each month. These are people who already listen to music similar to yours.
          </StyledText>
        </StyledForm>
      </StyledContent>
    </StyledContainer>
  )
}

function mapStateToProps(state: RootState) {
  return {
    isLoading: state.loading.isLoading,
    currentUser: state.auth.currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators( {
    adminLogin,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AdminLoginForm)
