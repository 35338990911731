import {
  ANALYTICS_AUTOMATIC_SCHEDULING,
  ANALYTICS_BETRAYERS,
  ANALYTICS_FOLLOW_BACK_USERS,
  ANALYTICS_FOLLOWER_COUNTS,
  ANALYTICS_IS_FOLLOWING_ENABLED,
  ANALYTICS_JUICY_SOURCES,
  ANALYTICS_NON_FOLLOW_BACK_USERS,
  ANALYTICS_ONE_WAY_FOLLOWS,
  ANALYTICS_RECOMMENDED_SOURCES,
  ANALYTICS_TO_FOLLOW_USERS,
  ANALYTICS_UNFOLLOW_ONE_WAY_FOLLOWS,
  AUTH_USER,
  AUTH_USER_OB_STEP,
  AUTH_USER_PLAN_REGISTRATION,
  AUTH_USER_PLAN_SELECTION,
  AUTH_USER_SC_CONNECT,
  AUTH_USER_SOURCED_ACCOUNTS,
  FLASH_ERROR,
  FLASH_SUCCESS,
  LOADING,
  REFERRAL_REFERRALS,
} from './types';
import {PasswordEditInputs, SigninInputs, SignupInputs, AdminLoginInputs} from '../types/AuthInputs';
import {Dispatch} from 'redux';
import {
  AnalyticsAutomaticSchedulingAction,
  AnalyticsBetrayersAction,
  AnalyticsFollowBackUsersAction,
  AnalyticsFollowerCountsAction,
  AnalyticsIsFollowingEnabledAction,
  AnalyticsJuicySourcesAction,
  AnalyticsNonFollowBackUsersAction,
  AnalyticsOneWayFollowsAction,
  AnalyticsRecommendedSourcesAction,
  AnalyticsToFollowUsersAction,
  AnalyticsUnfollowOneWayFollowsAction,
  AuthUserAction,
  AuthUserOnboardingStepAction,
  AuthUserPlanRegistrationAction,
  AuthUserPlanSelectionAction,
  AuthUserSCConnectAction,
  AuthUserSourcedAccountsAction,
  FlashErrorAction,
  FlashSuccessAction,
  ReferralReferralsAction,
  SetLoadingAction
} from '../types/ReduxActions';
import {logEvent, setUserId, setUserProperties} from 'firebase/analytics';
import {onAuthStateChanged, signInWithEmailAndPassword, signOut, signInWithCustomToken} from 'firebase/auth';
import {analytics, auth} from '../common/firebase';
import * as FirestoreHelper from '../helpers/FirestoreHelper';
import ApiHelper from '../helpers/ApiHelper';
import {Stripe, StripeCardElement, StripeElements} from '@stripe/stripe-js'
import {CurrentUser, OnboardingStep, SelectedPlanType, SubscriptionType} from '../types/Users';
import * as keys from '../common/keys';
import * as analyticsHelper from '../common/analyticsHelper';
import {AmplitudeEventProps} from '../types/AmplitudeEventProps';
import {DiscountCodeValidationResult, SuccessfulDiscountCodeValidationResult} from "../types/Purchasing";
import {
  CachedArtistEligibilityInputs,
  StemMasteringWorkshopReplayInputs,
  ViewingTokenValidationResult
} from '../types/StemMastering'
import {Timestamp} from 'firebase/firestore'

export const resetPassword = (inputs: PasswordEditInputs, callback: (error?: Error) => void) => async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const { password, token } = inputs;
    await ApiHelper.resetPassword(password, token);
    dispatchFlashMessage('Your password was successfully changed.', true, dispatch);
    callback();
  } catch (error) {
    dispatchFlashMessage(
        'Looks like the link you used has expired or is invalid. Please request a new one.',
        false,
        dispatch
    );
    callback(error);
  }
  dispatch(createLoadingAction(false));
};

export const validateViewingToken = (token: string, callback: (error?: Error) => void) => async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch)
  dispatch(createLoadingAction(true))
  try {
    const validationResult = await ApiHelper.validateViewingToken(token)
    if (validationResult === ViewingTokenValidationResult.VIEWING_ALLOWED) {
      callback();
    } else {
      const errorMessage = validationResult === ViewingTokenValidationResult.EXPIRED_TOKEN ? 'Looks like the link you used has expired. Please request a new one.' :
        'Looks like the link you are attempting to use is invalid. Please check the link and try again.'
      dispatchFlashMessage(errorMessage, false, dispatch)
      callback(new Error(errorMessage))
    }
  } catch (error) {
    dispatchFlashMessage(
      'Looks like the link you used has expired or is invalid. Please request a new one.',
      false,
      dispatch
    );
    callback(error);
  }
  dispatch(createLoadingAction(false));
}

export const requestPasswordResetEmail = (email: string, callback: () => void) => async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    await ApiHelper.requestPasswordResetEmail(email.toLowerCase());
    callback();
    dispatchFlashMessage('Please check your email to reset your password', true, dispatch);
  } catch (error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
  }
  dispatch(createLoadingAction(false));
};

const dispatchRegisterForPlan = async (currentUser: CurrentUser, plan: SubscriptionType, dispatch: Dispatch) => {
  try {
    await FirestoreHelper.subscribeUserToPlan(currentUser.id, currentUser.scId, plan);
    const registrationAction: AuthUserPlanRegistrationAction = {
      type: AUTH_USER_PLAN_REGISTRATION,
      payload: plan,
    };
    dispatch(registrationAction);
  } catch (error) {
    dispatchFlashMessage(error.message, false, dispatch);
  }
};

const dispatchSelectPlan = async (currentUser: CurrentUser, plan: SelectedPlanType, dispatch: Dispatch) => {
  try {
    const planSelectionAction: AuthUserPlanSelectionAction = {
      type: AUTH_USER_PLAN_SELECTION,
      payload: plan,
    };
    dispatch(planSelectionAction);
    await FirestoreHelper.updateSelectedPlan(currentUser.id, plan);
  } catch (error) {
    console.error(error);
  }
};

export const restartOnboarding = (ffUserId: string) => async (dispatch: Dispatch) => {
  const nextStepAction: AuthUserOnboardingStepAction = {
    type: AUTH_USER_OB_STEP,
    payload: 1,
  };
  dispatch(nextStepAction);
  await FirestoreHelper.updateOnboardingStep(ffUserId, 1);
};


export const selectPlan = (currentUser: CurrentUser, plan: SelectedPlanType) => async (dispatch: Dispatch) => {
  await dispatchSelectPlan(currentUser, plan, dispatch);
};

export const resumeSubscription = (currentUser: CurrentUser, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const apiHelper = new ApiHelper(currentUser.id);await apiHelper.setAutoRenew(true);
    // Set selectedPlan to the current subscription so that we have the correct cancelled state for this user
    await dispatchSelectPlan(currentUser, currentUser.subscription, dispatch);
    setUserProperties(analytics, {[keys.AnalyticsUserPropertySubscriptionKey]: currentUser.subscription as string});
    dispatchFlashMessage('Your subscription has been resumed and will renew automatically', true, dispatch);
    dispatch(createLoadingAction(false));
    callback();
  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
});

export const submitFeedbackAndCancelSubscription = (currentUser: CurrentUser, feedback: string, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const apiHelper = new ApiHelper(currentUser.id);

    await apiHelper.submitFeedback(feedback, true);

    const subscription = await apiHelper.setAutoRenew(false);
    // Set selectedPlan to null so that we can show "resume subscription" link instead of cancel on edit sub page
    await dispatchSelectPlan(currentUser, null, dispatch);
    setUserProperties(analytics, { [keys.AnalyticsUserPropertySubscriptionKey]: keys.AnalyticsUserPropertySubscriptionNone});
    const subscriptionPeriodEnd: Date = new Date(subscription.current_period_end * 1000);
    dispatchFlashMessage(`Your subscription was successfully cancelled. You will continue to enjoy all the benefits of your Artist Management plan until ${subscriptionPeriodEnd.toDateString()}, after which you will no longer be billed.`, true, dispatch);
    dispatch(createLoadingAction(false));
    callback();
  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
});

export const submitFeedbackAndKeepSubscription = (currentUser: CurrentUser, feedback: string, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const apiHelper = new ApiHelper(currentUser.id);
    if (feedback) {
      await apiHelper.submitFeedback(feedback, false);
    }
    dispatchFlashMessage(`We are happy you decided to keep your subscription.`, true, dispatch);
    dispatch(createLoadingAction(false));
    callback();

  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
});

export const changeSubscription = (currentUser: CurrentUser, plan: SubscriptionType, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const apiHelper = new ApiHelper(currentUser.id);
    await apiHelper.changeSubscriptionPlan(plan);
    await dispatchSelectPlan(currentUser, plan, dispatch);
    await dispatchRegisterForPlan(currentUser, plan, dispatch);
    setUserProperties(analytics, { [keys.AnalyticsUserPropertySubscriptionKey]: plan as string});
    dispatchFlashMessage(`Your subscription was successfully changed to ${plan}`, true, dispatch);
    dispatch(createLoadingAction(false));
    callback();
  } catch (error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
});

export const requestStemMasteringWorkshopReplay = (inputs: StemMasteringWorkshopReplayInputs, callback: (leadId: string) => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch)
  dispatch(createLoadingAction(true))
  try {
    // TODO: execute logic to request stem mastering replay
    const apiHelper = new ApiHelper('blank')
    const leadId = await apiHelper.requestStemMasteringReplay(inputs.firstName, inputs.email.toLowerCase())
    dispatchFlashMessage(`An email with a link to the replay was sent to ${inputs.email}`, true, dispatch);
    dispatch(createLoadingAction(false))
    callback(leadId)
  } catch (error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
})

export const verifyCachedArtistEligibility = (inputs: CachedArtistEligibilityInputs, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch)
  dispatch(createLoadingAction(true))
  try {
    // TODO: execute logic to verify cached artist eligibility
    const apiHelper = new ApiHelper('blank')
    await apiHelper.verifyArtistEligibility(inputs.leadId, inputs.soundCloudProfileUrl)
    dispatchFlashMessage(`You are all set. Look out for an email from us within the next 2 business days.`, true, dispatch);
    dispatch(createLoadingAction(false))
    callback()
  } catch (error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
})

export const signup = (inputs: SignupInputs, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch)
  dispatch(createLoadingAction(true))

  try {
    const apiHelper = new ApiHelper('blank')
    await apiHelper.createUser(inputs.soundCloudProfileUrl, inputs.email.toLowerCase(), inputs.password, inputs.firstName)
    const credential = await signInWithEmailAndPassword(auth, inputs.email, inputs.password)
    const { user: firebaseUser } = credential

    const currentUser = await getCurrentUser(firebaseUser.uid)
    dispatch(createAuthUserAction(currentUser))

    setUserId(analytics, currentUser.id)
    setUserProperties(analytics, {[keys.AnalyticsUserPropertySubscriptionKey]: keys.AnalyticsUserPropertySubscriptionNone})
    logEvent(analytics, 'sign_up', {
      method: keys.AnalyticsMethodEmailAndPassword,
    });

    dispatch(createLoadingAction(false))

    callback()

  } catch (error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
});

export const adminLogin = (inputs: AdminLoginInputs, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch)
  dispatch(createLoadingAction(true))
  let currentUser: CurrentUser | null = null;
  try {
    const apiHelper = new ApiHelper('blank')
    const token = await apiHelper.createCustomToken(inputs.username, inputs.password)
    const credential = await signInWithCustomToken(auth, token)
    const { user: firebaseUser } = credential

    if (!firebaseUser) {
      const error = new Error("We've made a mistake!");
      dispatchFlashMessage(error.message, false, dispatch);
      dispatch(createLoadingAction(false));
      return;
    }

    currentUser = await getCurrentUser(firebaseUser.uid);
    dispatch(createAuthUserAction(currentUser));
    setUserId(analytics, currentUser.id);
    logEvent(analytics, 'login', {
      method: keys.AnalyticsMethodCustomToken,
    });
    dispatch(createLoadingAction(false));
    callback();
  } catch (ignore) {
    const error = new Error('The credentials you entered are invalid. Please check them and try again.');
    dispatchFlashMessage(error.message, false, dispatch);
    dispatch(createLoadingAction(false));
  }
})

export const signin = (inputs: SigninInputs, callback: () => void) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));

  let currentUser: CurrentUser | null = null;
  try {
    // if (!inputs.shouldRemember) {
    //   await auth.setPersistence(keys.PERSISTENCE_SESSION);
    // }
    const credential = await signInWithEmailAndPassword(auth, inputs.email, inputs.password);

    const { user: firebaseUser } = credential;

    if (!firebaseUser) {
      const error = new Error("We've made a mistake!");
      dispatchFlashMessage(error.message, false, dispatch);
      dispatch(createLoadingAction(false));
      return;
    }

    currentUser = await getCurrentUser(firebaseUser.uid);
    dispatch(createAuthUserAction(currentUser));
    setUserId(analytics, currentUser.id);
    logEvent(analytics, 'login', {
      method: keys.AnalyticsMethodEmailAndPassword,
    });

    dispatch(createLoadingAction(false));

    callback();

  } catch (ignore) {
    const error = new Error('The email and password don\'t match. Please check them and try again.');
    dispatchFlashMessage(error.message, false, dispatch);
    dispatch(createLoadingAction(false));
  }
});

export const submitSourceFollowersRequest = (currentUser: CurrentUser, url: string) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const apiHelper = new ApiHelper(currentUser.id);
    const numScheduled = await apiHelper.scheduleFollows(url);
    await dispatchSourcedAccountsAction(currentUser, numScheduled, dispatch);
    dispatchFlashMessage(`${numScheduled} users were added to your list of scheduled follows`, true, dispatch);
    dispatch(createLoadingAction(false));
  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    await dispatchSourcedAccountsAction(currentUser, 0, dispatch);
    dispatchFlashMessage(errorMessage, false, dispatch);
    dispatch(createLoadingAction(false));
  }
  const ampEventProps: AmplitudeEventProps = {
    [keys.AmpPropSeedUrl]: url,
  };
  analyticsHelper.recordClick(keys.AmpElementScheduleFollowsButton, ampEventProps);
});

const getCurrentUser = async (id: string) => {
  try {
    const combinedUserData = await FirestoreHelper.getCombinedUserData(id);
    const currentUser: CurrentUser = {
      ...combinedUserData,
      id,
    };
    return Promise.resolve(currentUser);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const signIntoSoundCloud = (currentUser: CurrentUser, scPassword: string) => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const apiHelper = new ApiHelper(currentUser.id);
    await apiHelper.signIntoSoundCloud(scPassword);

    await FirestoreHelper.setIsSCAuthed(currentUser.id, true);
    await dispatchNextOnboardingStep(currentUser, dispatch);

    const scConnectAction: AuthUserSCConnectAction = {
      type: AUTH_USER_SC_CONNECT,
      payload: true,
    };
    dispatch(scConnectAction);

    dispatchFlashMessage('Authentication successful', true, dispatch);
  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
  }
  dispatch(createLoadingAction(false));
});

const dispatchNextOnboardingStep = async (currentUser: CurrentUser, dispatch: Dispatch) => {
  const nextStepInt = currentUser.onboardingStep + 1;
  if (nextStepInt <= 3) {
    const nextStep = nextStepInt as OnboardingStep;
    await FirestoreHelper.updateOnboardingStep(currentUser.id, nextStep);
    const nextStepAction: AuthUserOnboardingStepAction = {
      type: AUTH_USER_OB_STEP,
      payload: nextStep,
    };
    dispatch(nextStepAction);
  }
};

export const nextOnboardingStep = (currentUser: CurrentUser) => ( async (dispatch: Dispatch) => {
  await dispatchNextOnboardingStep(currentUser, dispatch);
});

export const previousOnboardingStep = (currentUser: CurrentUser) => ( async (dispatch: Dispatch) => {
  dispatchPreviousOnboardingStep(currentUser, dispatch);
});

const dispatchPreviousOnboardingStep = (currentUser: CurrentUser, dispatch: Dispatch) => {
  console.log('inside previous onboarding step action');
  const previousStep = currentUser.onboardingStep - 1;
  console.log('previous step is ', previousStep);
  if (previousStep > 0) {
    const previousStepAction: AuthUserOnboardingStepAction = {
      type: AUTH_USER_OB_STEP,
      payload: previousStep as OnboardingStep,
    };
    console.log('dispatching previousStepAction');
    dispatch(previousStepAction);
  }
};

export const requestSCPasswordReset = (currentUser: CurrentUser) => (async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    const apiHelper = new ApiHelper(currentUser.id);
    const addresses = await apiHelper.requestSCPasswordReset();
    const message = `Sent password reset instructions to ${addresses.join(', ')}. Please follow the steps in the email before proceeding.`;
    dispatchFlashMessage(message, true, dispatch);
  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
  }
  dispatch(createLoadingAction(false));
});

export const signout = (currentUser: CurrentUser)  => ( async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  dispatch(createLoadingAction(true));
  try {
    await signOut(auth);
    dispatch(createAuthUserAction(null));
    // analyticsHelper.logout();
  } catch (error) {
    dispatchFlashMessage(error.message, false, dispatch)
  }
  dispatch(createLoadingAction(false));
});

export const setIsFollowingEnabled = (currentUser: CurrentUser, isFollowingEnabled: boolean) => (async (dispatch: Dispatch) => {
  const analyticsIsFollowingEnabledAction: AnalyticsIsFollowingEnabledAction = {
    type: ANALYTICS_IS_FOLLOWING_ENABLED,
    payload: isFollowingEnabled,
  }
  dispatch(analyticsIsFollowingEnabledAction)
  await FirestoreHelper.setIsFollowingEnabled(currentUser.scId, isFollowingEnabled).catch(error => {
    console.log('Failed to set isFollowingEnabled toggle', error.message)
  })
})

export const setUnfollowOneWayFollows = (currentUser: CurrentUser, unfollowOneWayFollows: boolean) => (async (dispatch: Dispatch) => {
  const analyticsUnfollowOneWayFollowsAction: AnalyticsUnfollowOneWayFollowsAction = {
    type: ANALYTICS_UNFOLLOW_ONE_WAY_FOLLOWS,
    payload: unfollowOneWayFollows,
  }
  dispatch(analyticsUnfollowOneWayFollowsAction)
  await FirestoreHelper.setUnfollowOneWayFollows(currentUser.scId, unfollowOneWayFollows).catch(error => {
    console.log('Failed to set unfollowOneWayFollows toggle', error.message)
  })
})

export const refreshSoundCloudUserData = (documentData: any) => ((dispatch: Dispatch) => {
  const followerCounts = FirestoreHelper.getFollowerCounts(documentData)
  const analyticsFollowerCountsAction: AnalyticsFollowerCountsAction = {
    type: ANALYTICS_FOLLOWER_COUNTS,
    payload: followerCounts,
  };
  dispatch(analyticsFollowerCountsAction);

  const juicySources = FirestoreHelper.getJuicySources(documentData)
  juicySources.sort((a, b) => b.numHits - a.numHits)
  const analyticsJuicySourcesAction: AnalyticsJuicySourcesAction = {
    type: ANALYTICS_JUICY_SOURCES,
    payload: juicySources.filter((juicySource) => juicySource.origin === 'user'),
  };
  dispatch(analyticsJuicySourcesAction);

  const isFollowingEnabled = FirestoreHelper.getIsFollowingEnabled(documentData)
  const analyticsIsFollowingEnabledAction: AnalyticsIsFollowingEnabledAction = {
    type: ANALYTICS_IS_FOLLOWING_ENABLED,
    payload: isFollowingEnabled,
  }
  dispatch(analyticsIsFollowingEnabledAction)

  const unfollowOneWayFollows = FirestoreHelper.getUnfollowOneWayFollows(documentData)
  const analyticsUnfollowOneWayFollowsAction: AnalyticsUnfollowOneWayFollowsAction = {
    type: ANALYTICS_UNFOLLOW_ONE_WAY_FOLLOWS,
    payload: unfollowOneWayFollows,
  }
  dispatch(analyticsUnfollowOneWayFollowsAction)

  const automaticScheduling = FirestoreHelper.getAutomaticScheduling(documentData)
  const analyticsAutomaticSchedulingAction: AnalyticsAutomaticSchedulingAction = {
    type: ANALYTICS_AUTOMATIC_SCHEDULING,
    payload: automaticScheduling,
  }
  dispatch(analyticsAutomaticSchedulingAction)

  const recommendedSources = FirestoreHelper.getRecommendedSources(documentData)
  const analyticsRecommendedSourcesAction: AnalyticsRecommendedSourcesAction = {
    type: ANALYTICS_RECOMMENDED_SOURCES,
    payload: recommendedSources,
  }
  dispatch(analyticsRecommendedSourcesAction)

  const oneWayFollows = FirestoreHelper.getOneWayFollows(documentData)
  const analyticsOneWayFollowsAction: AnalyticsOneWayFollowsAction = {
    type: ANALYTICS_ONE_WAY_FOLLOWS,
    payload: {
      loading: false,
      items: oneWayFollows,
    }
  }
  dispatch(analyticsOneWayFollowsAction)
})

export const setAutomaticScheduling = (currentUser: CurrentUser, automaticScheduling: boolean) => (async (dispatch: Dispatch) => {
  const analyticsAutomaticSchedulingAction: AnalyticsAutomaticSchedulingAction = {
    type: ANALYTICS_AUTOMATIC_SCHEDULING,
    payload: automaticScheduling,
  }
  dispatch(analyticsAutomaticSchedulingAction)
  await FirestoreHelper.setAutomaticScheduling(currentUser.scId, automaticScheduling).catch(error => {
    console.log('Failed to set automaticScheduling toggle', error.message)
  })
})

export const fetchReferralData = (scId: string, promoterInitiatedAtMillis: number) => ( async (dispatch: Dispatch) => {
  dispatch(createLoadingAction(true));
  try {
    const promoterInitiatedAt = Timestamp.fromMillis(promoterInitiatedAtMillis)
    const referrals =  await FirestoreHelper.getEligibleReferrals(scId, promoterInitiatedAt)
    const referralReferralsAction: ReferralReferralsAction = {
      type: REFERRAL_REFERRALS,
      payload: referrals,
    }
    dispatch(referralReferralsAction)
  } catch (error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    if (errorMessage.msg) {
      errorMessage = errorMessage.msg
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
  }
  dispatch(createLoadingAction(false));
})

export const fetchAnalyticsData = (ffUserId: string, scId: string, selectedPlan: SelectedPlanType) => ( async (dispatch: Dispatch) => {
  // START Fetch Firestore data
  let [
    toFollowQueue,
    followBackUsers,
    oldFollowBackUsers,
    nonFollowBackUsers,
    betrayers,
  ] = await Promise.all([
    FirestoreHelper.getToFollowQueue(scId),
    FirestoreHelper.getFollowBacks(scId),
    FirestoreHelper.getOldFollowBacks(scId),
    FirestoreHelper.getNonFollowBacks(scId),
    FirestoreHelper.getBetrayers(scId),
  ])

  const analyticsToFollowUsersAction: AnalyticsToFollowUsersAction = {
    type: ANALYTICS_TO_FOLLOW_USERS,
    payload: toFollowQueue,
  };
  dispatch(analyticsToFollowUsersAction);

  const analyticsFollowBackUsersAction: AnalyticsFollowBackUsersAction = {
    type: ANALYTICS_FOLLOW_BACK_USERS,
    payload: [...followBackUsers, ...oldFollowBackUsers],
  };
  dispatch(analyticsFollowBackUsersAction);

  const analyticsNonFollowBackUsersAction: AnalyticsNonFollowBackUsersAction = {
    type: ANALYTICS_NON_FOLLOW_BACK_USERS,
    payload: nonFollowBackUsers,
  };
  dispatch(analyticsNonFollowBackUsersAction);

  const analyticsBetrayersAction: AnalyticsBetrayersAction = {
    type: ANALYTICS_BETRAYERS,
    payload: betrayers,
  };
  dispatch(analyticsBetrayersAction);
  // END Fetch firestore data
});

export const acceptPayment = (stripe: Stripe,
                              elements: StripeElements,
                              cardElement: StripeCardElement,
                              currentUser: CurrentUser,
                              callback: () => void,
                              discountCodeValidationResult?:  SuccessfulDiscountCodeValidationResult,
                              referrerScId?: string) =>
    ( async (dispatch: Dispatch) => {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email: currentUser.email,
        },
      });
      if (result.error) {
        // show error to client
        const errorMessage = result.error.message || 'Something went wrong. Please try again later.';
        dispatchFlashMessage(errorMessage, false, dispatch);
        callback();
      }
      else if (!result.paymentMethod) {
        // show error to client
        const errorMessage = 'Something went wrong. Please try again later.';
        dispatchFlashMessage(errorMessage, false, dispatch);
        callback();
      }
      else {
        dispatch(createLoadingAction(true));
        const apiHelper = new ApiHelper(currentUser.id);
        try {
          const plan: SubscriptionType  = currentUser.selectedPlan === 'INTRO' ? 'BASIC' : currentUser.selectedPlan

          const subscription = await apiHelper.createOrUpdateSubscription(result.paymentMethod.id, currentUser.email, plan, discountCodeValidationResult, referrerScId);
          const {latest_invoice} = subscription;
          const {payment_intent} = latest_invoice;

          if (payment_intent) {
            const {client_secret, status} = payment_intent;

            if (status === 'requires_action') {
              const result = await stripe.confirmCardPayment(client_secret);
              if (result.error) {
                // The card was declined (i.e. insufficient funds, card has expired, etc)
                const errorMessage = result.error.message || 'Something went wrong. Please try again later.';
                dispatchFlashMessage(errorMessage, false, dispatch);

              } else {
                await apiHelper.retrieveSubscription(subscription.id);
                await dispatchRegisterForPlan(currentUser, plan, dispatch);
                setUserProperties(analytics, { [keys.AnalyticsUserPropertySubscriptionKey]: currentUser.selectedPlan as string});
                dispatchFlashMessage('Payment was successful', true, dispatch);
              }

            } else {
              await dispatchRegisterForPlan(currentUser, plan, dispatch);
              setUserProperties(analytics, { [keys.AnalyticsUserPropertySubscriptionKey]: currentUser.selectedPlan as string});
              dispatchFlashMessage('Payment was successful', true, dispatch);
            }
          } else { // free month via referral
            await dispatchRegisterForPlan(currentUser, plan, dispatch);
            setUserProperties(analytics, { [keys.AnalyticsUserPropertySubscriptionKey]: currentUser.selectedPlan as string});
            dispatchFlashMessage('Welcome to Artist Management!', true, dispatch);
          }
        } catch (error) {
          const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
          let errorMessage;
          if (errorMessages.length) {
            errorMessage = errorMessages[0];
          } else {
            errorMessage = 'Something went wrong. Please try again later.'
          }
          if (errorMessage.msg) {
            errorMessage = errorMessage.msg
          }
          dispatchFlashMessage(errorMessage, false, dispatch);
        }
        dispatch(createLoadingAction(false));
      }
    });

const createAuthUserAction = (user: CurrentUser | null) => {
  const action: AuthUserAction = {
    type: AUTH_USER,
    payload: user,
  };

  return action;
};

const dispatchSourcedAccountsAction = async (currentUser: CurrentUser, numSourcedAccounts: number, dispatch: Dispatch) => {
  const requestDate = new Date();
  const sourcedAccountsAction: AuthUserSourcedAccountsAction = {
    type: AUTH_USER_SOURCED_ACCOUNTS,
    payload: {
      numSourcedAccounts,
      lastSourceRequest: requestDate,
    }
  };
  dispatch(sourcedAccountsAction);
  await FirestoreHelper.updateSourcedAccounts(currentUser.id, numSourcedAccounts, requestDate);
};

const createLoadingAction = (isLoading: boolean) => {
  const loadingAction: SetLoadingAction = {
    type: LOADING,
    payload: {
      isLoading,
    }
  };
  return loadingAction;
};

const dispatchFlashMessage = (message: string, success: boolean, dispatch: Dispatch) => {
  if (success) {
    dispatch(createFlashErrorAction(''));
    dispatch(createFlashSuccessAction(message));
  } else {
    dispatch(createFlashSuccessAction(''));
    dispatch(createFlashErrorAction(message));
  }
};

const dispatchClearFlashMessage = (dispatch: Dispatch) => {
  dispatch(createFlashSuccessAction(''));
  dispatch(createFlashErrorAction(''));
};

const createFlashSuccessAction = (message: string) => {
  const action: FlashSuccessAction = {
    type: FLASH_SUCCESS,
    payload: message,
  };
  return action;
};

const createFlashErrorAction = (message: string) => {
  const action: FlashErrorAction = {
    type: FLASH_ERROR,
    payload: message,
  };
  return action;
};

export const acceptDiscountCode = (currentUser: CurrentUser, referralOrCouponCode: string, callback: (discountCodeValidationResult: DiscountCodeValidationResult, referrerScId?: string) => void) => (async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch);
  const apiHelper = new ApiHelper(currentUser.id);
  if (!currentUser.selectedPlan || currentUser.selectedPlan === 'INTRO') {
    dispatchFlashMessage('You must select a plan first.', false, dispatch)
    callback(DiscountCodeValidationResult.NO_OFFER_INVALID_DISCOUNT_CODE)
    return
  }
  try {
    const {
      validationResult,
      referrerScId
    } = await apiHelper.validateDiscountCode(referralOrCouponCode, currentUser.selectedPlan);
    switch (validationResult) {
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_6_MONTHS:
      case DiscountCodeValidationResult.THIRTY_PERCENT_OFF_1_MONTH:
      case DiscountCodeValidationResult.HUNDRED_PERCENT_OFF_1_MONTH:
      case DiscountCodeValidationResult.TEN_PERCENT_OFF_1_MONTH:
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_1_MONTH: {
        dispatchFlashMessage('Discount code was successfully applied.', true, dispatch)
        break
      }
      case DiscountCodeValidationResult.NO_OFFER_INVALID_DISCOUNT_CODE: {
        dispatchFlashMessage('Discount code is invalid.', false, dispatch)
        break
      }
      case DiscountCodeValidationResult.NO_OFFER_ON_MONTHLY_PLANS: {
        dispatchFlashMessage('Discount code is not valid on a monthly plan.', false, dispatch)
        break
      }
      case DiscountCodeValidationResult.NO_OFFER_ONLY_FIRST_TIME_USER: {
        dispatchFlashMessage('Discount code could not be applied.', false, dispatch)
        break
      }
      case DiscountCodeValidationResult.NO_OFFER_ON_YEARLY_PLANS: {
        dispatchFlashMessage('Discount code is not valid on a yearly plan.', false, dispatch)
        break
      }
    }
    callback(validationResult, referrerScId);
  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch);
  }
});

export const attemptSCAuthorization = (currentUser: CurrentUser, code: string, state?: string) => (async (dispatch: Dispatch) => {
	dispatchClearFlashMessage(dispatch);
	dispatch(createLoadingAction(true));
	try {
		const apiHelper = new ApiHelper(currentUser.id);
		await apiHelper.connectSoundCloudAccount(code, state);

		await FirestoreHelper.setIsSCAuthed(currentUser.id, true);
		await dispatchNextOnboardingStep(currentUser, dispatch);

		const scConnectAction: AuthUserSCConnectAction = {
			type: AUTH_USER_SC_CONNECT,
			payload: true,
		};
		dispatch(scConnectAction);

		dispatchFlashMessage('SoundCloud successfully connected.', true, dispatch);
	} catch(error) {
		const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
		let errorMessage;
		if (errorMessages.length) {
			errorMessage = errorMessages[0];
		} else {
			errorMessage = 'Something went wrong. Please try again later.'
		}
		dispatchFlashMessage(errorMessage, false, dispatch);
	}
	dispatch(createLoadingAction(false));
});

export const initiateLoginFlow = (currentUser: CurrentUser, callback: (redirectUrl: string) => void) => (async (dispatch: Dispatch) => {
  dispatchClearFlashMessage(dispatch)
  dispatch(createLoadingAction(true))
  try {
    const apiHelper = new ApiHelper(currentUser.id)
    const { redirectUrl } = await apiHelper.initiateLoginFlow()
    callback(redirectUrl)
  } catch(error) {
    const errorMessages = (error.response && error.response.data && error.response.data.errors) || [error.message];
    let errorMessage;
    if (errorMessages.length) {
      errorMessage = errorMessages[0];
    } else {
      errorMessage = 'Something went wrong. Please try again later.'
    }
    dispatchFlashMessage(errorMessage, false, dispatch)
  }
})

export const verifyAuth = () => ((dispatch: Dispatch) => {
  dispatch(createLoadingAction(true));
  onAuthStateChanged(auth, async (firebaseUser) => {
    let currentUser;
    if (firebaseUser) {
      currentUser = await getCurrentUser(firebaseUser.uid).catch(async (error) => {
        console.error(error)
        await signOut(auth)
        return null
      })
      dispatch(createAuthUserAction(currentUser));
    } else {
      dispatch(createAuthUserAction(null));
    }
    dispatch(createLoadingAction(false));

    if (currentUser) {
      try {
        const apiHelper = new ApiHelper(currentUser.id);
        const scProfile = await apiHelper.getSCProfile(currentUser.scId);
        const lastLogin = new Date();
        let subscriptionCycleEnd: Date | null = null;
        if (currentUser.stripeSubscriptionId) {
          const subscription = await apiHelper.retrieveSubscription(currentUser.stripeSubscriptionId);
          subscriptionCycleEnd = new Date(subscription.current_period_end * 1000);
        }
        await FirestoreHelper.updateRefreshableFields(currentUser.id, scProfile, lastLogin, subscriptionCycleEnd);
        currentUser = await getCurrentUser(currentUser.id);
        analyticsHelper.setUserProperties(currentUser);
        dispatch(createAuthUserAction(currentUser));
      } catch (ignore) {}
    }
  });
});
