import {Referral} from '../../types/Referral'
import {FC} from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import {getFormattedCurrency} from '../../helpers/Utils'


type ReferralTableProps = {
  referrals: Referral[],
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
`

const Heading = styled.h2`
  margin: 0 0 -4px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2px;
`

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    padding: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;

    div {
        width: 100%;
        text-align: center;
    }

`

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    padding: 24px;

    div {
        width: 100%;
        text-align: center;
    }

`
const PurchaseDate = styled.div`
`
const ReferralId = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: block
  `}
`
const Plan = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: block
  `}
`

const PayoutDate = styled.div`
`
const PayoutAmount = styled.div`
`

const ReferralTable: FC<ReferralTableProps> = ({ referrals}) => {
  const getDisplayablePlan = (plan: Referral['plan']): Referral['plan'] => {
    switch(plan) {
      case 'BASIC': {
        return 'BASIC'
      }
      case 'PLATINUM_YEARLY':
      case 'PLATINUM': {
        return 'PLATINUM'
      }
      case 'GOLD_YEARLY':
      case 'GOLD': {
        return 'GOLD'
      }
    }
  }
  return (
    <StyledContainer>
      <Heading>
        Referrals
      </Heading>
      <Table>
        <TableHeader>
          <ReferralId>
            Referral ID
          </ReferralId>
          <PurchaseDate>
            Purchased On
          </PurchaseDate>
          <Plan>
            Plan
          </Plan>
          <PayoutAmount>
            Payout
          </PayoutAmount>
          <PayoutDate>
            Paid On
          </PayoutDate>
        </TableHeader>
        {referrals.map((referral) => {
          return (
            <TableRow>
              <ReferralId>
                {referral.id}
              </ReferralId>
              <PurchaseDate>
                {referral.purchasedAt.toDate().toLocaleDateString('en-US')}
              </PurchaseDate>
              <Plan>
                {getDisplayablePlan(referral.plan)}
              </Plan>
              <PayoutAmount>
                {getFormattedCurrency('en', 'USD', referral.payoutAmountUSD)}
              </PayoutAmount>
              <PayoutDate>
                {referral.paidOutAt ? referral.paidOutAt.toDate().toLocaleDateString('en-US') : '-'}
              </PayoutDate>
            </TableRow>
          )
        })}
      </Table>
    </StyledContainer>
  )
}

export default ReferralTable