import {AuthState, LoadingState, ReferralState, RootState} from './types/States'
import {bindActionCreators, compose, Dispatch} from 'redux'
import {connect} from 'react-redux'
import LoadingBars from './components/LoadingBars'
import {Redirect} from 'react-router-dom'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {CurrentUser} from './types/Users'
import { fetchReferralData } from './actions'
import PayoutHeader from './components/Referral/PayoutHeader'
import ReferralTable from './components/Referral/ReferralTable'
import media from 'styled-media-query'
import * as analyticsHelper from './common/analyticsHelper'
import * as keys from './common/keys'


const StyledContainer = styled.div`
  background-color: #faf6e8;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowParent = styled.div`
    ${media.greaterThan("medium")`
      max-width: 1080px;
    `}
`

type ReferralsProps = {
  currentUser: AuthState["currentUser"],
  isLoading: LoadingState['isLoading'],
  referrals: ReferralState['referrals'],
  fetchReferralData: typeof fetchReferralData,
}

const Referrals: React.FC<ReferralsProps> = (props: ReferralsProps) => {
  const { fetchReferralData, currentUser } = props
  const scId = currentUser?.scId
  const promoterInitiatedAtMillis = currentUser?.promoterInitiatedAt?.toMillis()

  useEffect(() => {
    if (!scId || !promoterInitiatedAtMillis) return
    fetchReferralData(scId, promoterInitiatedAtMillis)
  }, [scId, promoterInitiatedAtMillis, fetchReferralData]);

  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageReferralPayouts);
    window.scrollTo(0, 0);
  }, []);

  const renderLoadingOrRedirect = () => {
    if (props.isLoading) {
      return <LoadingBars caption='Loading your referrals. Hang tight...'/>;
    } else {
      return <Redirect to='/signup' />
    }
  };

  const renderLoadingOrMainContent = (currentUser: CurrentUser) => {
    if (props.isLoading) {
      return <LoadingBars caption='Loading your referrals. Hang tight...'/>
    } else {
      if (!currentUser.subscription || !currentUser.promoterInitiatedAt) {
        return (
          <Redirect to='/dashboard' />
        )
      } else {
        const { referrals } = props
        return (
          <RowParent>
            <PayoutHeader referrals={referrals} referrerScUsername={currentUser.scUsername} promoterInitiatedAt={currentUser.promoterInitiatedAt}/>
            {referrals.length > 0 &&
              <ReferralTable referrals={referrals}/>
            }
          </RowParent>
        )
      }
    }
  }


  const renderContent = () => {
    if (!currentUser) {
      return renderLoadingOrRedirect();
    } else {
      return renderLoadingOrMainContent(currentUser);
    }
  };

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  )
}

function mapStateToProps(state: RootState) {
  return {
    isLoading: state.loading.isLoading,
    currentUser: state.auth.currentUser,
    referrals: state.referral.referrals,
  }
}
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    fetchReferralData,
  }, dispatch)
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Referrals);