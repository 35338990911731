import React, { useEffect, useState, MouseEvent } from 'react';
import {bindActionCreators, compose, Dispatch} from 'redux';
import { logEvent } from 'firebase/analytics';
import { BsGraphUpArrow } from 'react-icons/bs'
import { analytics } from './common/firebase';
import * as keys from './common/keys';
import * as analyticsHelper from './common/analyticsHelper';
import { useHistory } from 'react-router-dom';
import {AnalyticsState, AuthState, LoadingState, RootState} from './types/States';
import { connect } from 'react-redux';
import styled from 'styled-components';
import media from 'styled-media-query';
import SoundCloudUsersModal, { GraphModal } from './SoundCloudUsersModal';
import SCUserListItem from './SCUserListItem';
import SourceFollowersForm from './SourceFollowersForm';
import {SourcedSCUser, OneWayFollow} from './types/SoundCloudUsers';
import Button from './Button';
import NewUserModal from './NewUserModal';
import FollowerCountGraph from "./components/FollowerCountGraph";
import Tooltip from '@mui/material/Tooltip';
import RecommendedTrack from './components/RecommendedTrack';
import { onSnapshot } from 'firebase/firestore';
import { getSoundCloudUserDoc } from './helpers/FirestoreHelper';
import {
    setIsFollowingEnabled,
    setUnfollowOneWayFollows,
    restartOnboarding,
    setAutomaticScheduling,
    refreshSoundCloudUserData,
} from "./actions";
import AMToggle from "./components/AMToggle";
import LoadingBars from "./components/LoadingBars";

type AnalyticsDashboardProps = {
    isBetrayersModalOpen?: boolean,
    isToFollowUsersModalOpen?: boolean,
    isFollowBackUsersModalOpen?: boolean,
    isNonFollowBackUsersModalOpen?: boolean,
    isOneWayFollowsModalOpen?: boolean,
    isLoading: LoadingState["isLoading"]
    currentUser: AuthState["currentUser"]
    toFollowUsers: AnalyticsState['toFollowUsers'],
    followBackUsers: AnalyticsState['followBackUsers'],
    nonFollowBackUsers: AnalyticsState['nonFollowBackUsers'],
    followerCounts: AnalyticsState['followerCounts'],
    oneWayFollows: AnalyticsState['oneWayFollows'],
    recommendedSources: AnalyticsState['recommendedSources'],
    juicySources: AnalyticsState['juicySources'],
    betrayers: AnalyticsState['betrayers'],
    isFollowingEnabled: AnalyticsState['isFollowingEnabled'],
    unfollowOneWayFollows: AnalyticsState['unfollowOneWayFollows'],
    automaticScheduling: AnalyticsState['automaticScheduling'],
    setIsFollowingEnabled: typeof setIsFollowingEnabled,
    setUnfollowOneWayFollows: typeof setUnfollowOneWayFollows,
    setAutomaticScheduling: typeof setAutomaticScheduling,
    restartOnboarding: typeof restartOnboarding,
    refreshSoundCloudUserData: typeof refreshSoundCloudUserData,
}

const StyledProfilePictureMask = styled.div`
  background-color: ${props => props.theme.colors.orange};
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 77px;
  height: 77px;
  ${media.greaterThan("medium")`
    width: 150px;
    height: 150px;
  `}
`;

const StyledProfilePictureContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const UserProfilePicture = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
`;

const StyledHeading = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
`;

const StyledAnalyticsDashboardHeader = styled.div`
    flex-grow: 2;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 500px;
    margin: 0 auto;
`;

const StyledProfileHeaderRow = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledStatsSection = styled.div`
    max-width: 890px;
`;

const StyledScoreSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledPlanDisplaySection = styled.div`
    text-align: center;
    font-size: 14px;
    ${media.greaterThan("medium")`
        font-size: 18px
    `}
`;

const StyledStatsRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 90vw;
    justify-content: space-between;
`;

const StyledGraphButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
  width: calc(100vw - 40px);
  ${media.greaterThan('medium')`
    width: unset;
  `}
  
`

const StyledStatsItem = styled.div`
    flex-basis: 150px;
    flex-shrink: 0;
    flex-grow: 4;
    cursor: pointer;
    padding: 16px 5px 0;
`;

const StyledDataField = styled.div`
    text-align: center;
`;

const StyledBlurredDataField = styled.div`
    text-align: center;
    filter: blur(5px);
`;

const StyledMiniButton = styled.div`
    text-align: center;
    font-size: 14px;
    padding-top: 0.05em;
    border: ${props => `1px solid #ADB1B9`};
    background-color: #EDEEF1;
    color: ${props => props.theme.colors.black};
    border-radius: 10px;
    ${media.greaterThan("medium")`
        font-size: 16px;
    `}
`;

const StyledGraphButton = styled.div<{$isDisabled?: boolean}>`
    width: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    padding: 5px 20px;
    border: ${props => `1px solid #ADB1B9`};
    background-color: #EDEEF1;
    color: ${props => props.theme.colors.black};
    opacity: ${({ $isDisabled }) => $isDisabled ? 0.5 : 1};
    border-radius: 10px;
    ${media.greaterThan("medium")`
        font-size: 16px;
    `}
`;

const StyledAnalyticsSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 36px;
    ${media.greaterThan("medium")`
        padding-bottom: 48px;
    `}
`;

const StyledUpgradeModalContent = styled.div`
    height: calc(100% - 0.5em);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const StyledInfoModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 0.75em;
`;

const StyledModalTextContainer = styled.div`
    margin: 0.5em 1.5em 1.5em;
    text-align: center;
`;

const StyledInfoModalTextHeader = styled.div`
    text-align: left;
    margin-left: 2.2em;
    margin-right: 0.2em;
    margin-top: 20px;
    font-weight: 510;
`;

const StyledInfoModalTextFooter = styled.div`
    text-align: left;
    margin: 10px 0.2em 10px 2.2em;
`;

const StyledButton = styled(Button)`
  display: block;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
  width: 300px;
  align-self: center;
`;

const StyledEditSpan = styled.span`
    font-size: 20px;
    cursor: pointer;
    margin-left: 4px;
    position: relative;
    top: 2px;
    ${media.greaterThan("medium")`
        font-size: 24px;
        margin-left: 7px;
    `}
`;

const StyledRecommendedSourcesContainer = styled.div`
  width: calc(100vw - 40px);
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.greaterThan('medium')`
    padding: 0 200px;
    width: unset;
  `}
`;

const StyledComeBackSoonHint = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
        width: 400px;
    }
    font-family: 'Oswald', sans-serif;
    text-align: center;
`

const StyledRecommendedSourcesTitle = styled.div`
  text-align: center;
  padding: 0 40px;
  margin-bottom: 20px;
`;

const StyledRecommendedSources = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`

const StyledLoadingBars = styled(LoadingBars)`
  height: 30px !important;
  img {
      height: 30px;
  }
`

const AnalyticsDashboard: React.FC<AnalyticsDashboardProps> = (props) => {

    const history = useHistory();
    const [isOneWayFollowsModalOpen, setIsOneWayFollowsModalOpen] = useState(props.isOneWayFollowsModalOpen || false)
    const [isBetrayersModalOpen, setIsBetrayersModalOpen] = useState(props.isBetrayersModalOpen || false);
    const [isNonFollowBackUsersModalOpen, setIsNonFollowBackUsersModalOpen] = useState(props.isNonFollowBackUsersModalOpen || false);
    const [isFollowBackUsersModalOpen, setIsFollowBackUsersModalOpen] = useState(props.isFollowBackUsersModalOpen || false);
    const [isToFollowUsersModalOpen, setIsToFollowUsersModalOpen] = useState(props.isToFollowUsersModalOpen || false);
    const [isFollowerCountGraphModalOpen, setIsFollowerCountGraphModalOpen] = useState(false)
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(props.currentUser?.numSourceRequests === 0);


    useEffect(() => {
        logEvent(analytics, 'ff_page_view', {
            page_title: 'analytics_dashboard'
        });
        const pageName = props.currentUser?.selectedPlan === 'INTRO' ? keys.AmpPageIntroAnalyticsDashboard : keys.AmpPageAnalyticsDashboard
        analyticsHelper.recordPageView(pageName);
    }, [props.currentUser?.selectedPlan]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { currentUser, refreshSoundCloudUserData } = props

    useEffect(() => {
        const scId = currentUser?.scId
        if (scId) {
            const userDoc = getSoundCloudUserDoc(scId)
            const unsubscribe = onSnapshot(userDoc, (doc) => {
                if (!doc.exists()) {
                    return
                }
                const documentData = doc.data()
                if (!documentData) {
                    return
                }
                refreshSoundCloudUserData(documentData)
            })
            return () => unsubscribe()
        }

    }, [currentUser?.scId, refreshSoundCloudUserData])



    if (!currentUser) {
        return null
    }

    const getSources = (): string[] => {
        const sources = [...(props.juicySources.slice(0, 5).map(juicySource => juicySource.url))]
        // if (props.recommendedSources.length > 0 && sources.length < 5) {
        //     const numDesired = 5 - sources.length
        //     sources.push(...(props.recommendedSources).slice(0, numDesired).map(recommendedSource => recommendedSource.url))
        // }
        return sources
    }

    const sources = getSources()

    const getScheduledFollowsDisabledButtonText = (): string => {
        if (currentUser.selectedPlan === 'INTRO') {
            return 'Feature Disabled'
        }
        if (props.automaticScheduling && currentUser.subscription !== 'BASIC') {
            return 'Disabled'
        }
        return 'Plan Limit Reached'
    }

    const getScheduledFollowsLimit = (): number => {
        switch (currentUser.subscription) {
            case 'BASIC': {
                return 200
            }
            case 'GOLD_YEARLY':
            case 'GOLD': {
                return 500
            }
            case 'PLATINUM_YEARLY':
            case 'PLATINUM': {
                return 1000
            }
            default: {
                return 0
            }
        }
    }

    const isScheduleFollowersFormDisabled = (): boolean => {
        if (currentUser.selectedPlan === 'INTRO') {
            return true
        }
        const scheduledFollowsLimit = getScheduledFollowsLimit()
        return props.toFollowUsers.length > scheduledFollowsLimit
    };

    const handleFollowingSwitchFlip = async (isOn: boolean) => {
        props.setIsFollowingEnabled(currentUser, isOn);
    };

    const handleUnfollowOneWayFollowsSwitchFlip = async (isOn: boolean) => {
        props.setUnfollowOneWayFollows(currentUser, isOn)
    }

    const handleAutomaticSchedulingSwitchFlip = async (isOn: boolean) => {
        props.setAutomaticScheduling(currentUser, isOn)
    }

    const handleEditSubscriptionClick = (evt: MouseEvent) => {
        evt.preventDefault();
        if (currentUser.selectedPlan === 'INTRO') {
            props.restartOnboarding(currentUser.id)
            return;
        }
        history.push('/subscription/edit');
    };

    function openInfoModal() {
        logEvent(analytics, 'ff_button_click', {
            name: 'info_modal_open'
        });
        setIsInfoModalOpen(true);
    }

    function closeInfoModal() {
        setIsInfoModalOpen(false);
    }

    function openBetrayersModal() {
        setIsBetrayersModalOpen(true);
        logEvent(analytics, 'ff_button_click', {
            name: 'unfollowers_modal_open'
        });
        history.push('/dashboard/unfollowers');
    }

    function openOneWayFollowsModal() {
        setIsOneWayFollowsModalOpen(true);
        logEvent(analytics, 'ff_button_click', {
            name: 'one_way_follows_modal_open'
        });
        history.push('/dashboard/one-way-follows')
    }

    function closeBetrayersModal(){
        setIsBetrayersModalOpen(false);
        history.push('/dashboard')
    }

    function closeOneWayFollowsModal(){
        setIsOneWayFollowsModalOpen(false);
        history.push('/dashboard');
    }

    const navigateToSoundCloudProfile = () => {
        window.open(`https://soundcloud.com/${currentUser.scUsername}`, "_blank", "");
    };

    const handleBrowsePlansButtonClick = (modalName: string) => {
        logEvent(analytics, 'ff_button_click', {
            name: `${modalName}_browse_plans`,
        });
        if (currentUser.selectedPlan === 'INTRO') {
            props.restartOnboarding(currentUser.id)
            return;
        }
        history.push('/subscription/edit');
    };

    function openToFollowUsersModal() {
        setIsToFollowUsersModalOpen(true);
        logEvent(analytics, 'ff_button_click', {
            name: 'scheduled_follows_modal_open'
        });
        history.push('/dashboard/scheduled-follows');
    }

    function closeToFollowUsersModal(){
        setIsToFollowUsersModalOpen(false);
        history.push('/dashboard')
    }

    function openNonFollowBackUsersModal() {
        setIsNonFollowBackUsersModalOpen(true);
        logEvent(analytics, 'ff_button_click', {
            name: 'non_follow_backs_modal_open'
        });
        history.push('/dashboard/pending-follow-backs');
    }

    function closeNonFollowBackUsersModal(){
        setIsNonFollowBackUsersModalOpen(false);
        history.push('/dashboard')
    }

    function openFollowBackUsersModal() {
        setIsFollowBackUsersModalOpen(true);
        logEvent(analytics, 'ff_button_click', {
            name: 'follow_backs_modal_open'
        });
        history.push('/dashboard/follow-backs');
    }

    function closeFollowBackUsersModal(){
        setIsFollowBackUsersModalOpen(false);
        history.push('/dashboard')
    }

    function openFollowerCountGraphModal() {
        setIsFollowerCountGraphModalOpen(true)
    }

    function closeFollowerCountGraphModal() {
        setIsFollowerCountGraphModalOpen(false)
    }

    const getPlanText = () => {
        switch (currentUser.subscription) {
            case 'BASIC':
            case 'GOLD':
            case 'PLATINUM': {
                return currentUser.subscription
            }
            case 'PLATINUM_YEARLY': {
                return 'PLATINUM (Yearly)'
            }
            case "GOLD_YEARLY": {
                return 'GOLD (Yearly)'
            }
            case null: {
                return 'INTRO'
            }
        }
    }

    const scoreSection = () => {
        return (
            <StyledScoreSection>
                <StyledHeading>{currentUser.scUsername}</StyledHeading>
                <StyledPlanDisplaySection>
                    Plan: {getPlanText()}
                    <Tooltip title='Click here to edit your subscription' arrow>
                        <StyledEditSpan onClick={handleEditSubscriptionClick}>✎</StyledEditSpan>
                    </Tooltip>
                </StyledPlanDisplaySection>
            </StyledScoreSection>
        );
    };

    const betrayerItems = props.betrayers.map((betrayer: SourcedSCUser) => (
        <SCUserListItem key={betrayer.id} user={betrayer}/>
    ));

    const oneWayFollowItems = props.oneWayFollows.items.map((oneWayFollow: OneWayFollow) => (
      <SCUserListItem key={oneWayFollow.id} user={oneWayFollow}/>
    ))

    const followBackUserItems = props.followBackUsers.map((followBackUser: SourcedSCUser) => (
        <SCUserListItem key={followBackUser.id} user={followBackUser}/>
    ));

    const nonFollowBackUserItems = props.nonFollowBackUsers.map((nonFollowBackUser: SourcedSCUser) => (
        <SCUserListItem key={nonFollowBackUser.id} user={nonFollowBackUser}/>
    ));

    const toFollowUserItems = props.toFollowUsers.map((toFollowUser: SourcedSCUser) => (
        <SCUserListItem key={toFollowUser.id} user={toFollowUser}/>
    ));

    const generateModals = () => {
        if (currentUser.selectedPlan === 'INTRO') {
            return (
              <>
                  <SoundCloudUsersModal isModalOpen={isBetrayersModalOpen} closeModal={closeBetrayersModal} title='Unfollowers'>
                      <StyledUpgradeModalContent>
                          <StyledModalTextContainer>
                              This feature is only available to users on the Platinum plan.
                          </StyledModalTextContainer>
                          <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'unfollowers_modal')}>Browse plans</StyledButton>
                      </StyledUpgradeModalContent>
                  </SoundCloudUsersModal>
                  <SoundCloudUsersModal isModalOpen={isOneWayFollowsModalOpen} closeModal={closeOneWayFollowsModal} title='Manual One-way Follows'>
                      {oneWayFollowItems}
                  </SoundCloudUsersModal>
                  <SoundCloudUsersModal isModalOpen={isFollowBackUsersModalOpen} closeModal={closeFollowBackUsersModal} title='Follow-Backs'>
                      <StyledUpgradeModalContent>
                          <StyledModalTextContainer>
                              This feature is only available to users on the Gold or Platinum plans.
                          </StyledModalTextContainer>
                          <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'follow_backs_modal')}>Browse plans</StyledButton>
                      </StyledUpgradeModalContent>
                  </SoundCloudUsersModal>
                  <SoundCloudUsersModal isModalOpen={isNonFollowBackUsersModalOpen} closeModal={closeNonFollowBackUsersModal} title='Pending Follow-Backs'>
                      <StyledUpgradeModalContent>
                          <StyledModalTextContainer>
                              This feature is only available to users on the Gold or Platinum plans.
                          </StyledModalTextContainer>
                          <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'non_follow_backs_modal')}>Browse plans</StyledButton>
                      </StyledUpgradeModalContent>
                  </SoundCloudUsersModal>
                  <SoundCloudUsersModal isModalOpen={isToFollowUsersModalOpen} closeModal={closeToFollowUsersModal} title='Scheduled Follows'>
                      <StyledUpgradeModalContent>
                          <StyledModalTextContainer>
                              This feature is only available to users on a paid plan.
                          </StyledModalTextContainer>
                          <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'to_follow_modal')}>Browse plans</StyledButton>
                      </StyledUpgradeModalContent>
                  </SoundCloudUsersModal>
                  <GraphModal isModalOpen={isFollowerCountGraphModalOpen} closeModal={closeFollowerCountGraphModal} title='SoundCloud follower count over time'>
                      <StyledUpgradeModalContent>
                          <StyledModalTextContainer>
                              This feature is only available to users on a paid plan.
                          </StyledModalTextContainer>
                          <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'follower_count_graph_modal')}>Browse plans</StyledButton>
                      </StyledUpgradeModalContent>
                  </GraphModal>
              </>
            )
        }
        switch(currentUser.subscription) {
            case 'BASIC': {
                return (
                  <>
                      <SoundCloudUsersModal isModalOpen={isBetrayersModalOpen} closeModal={closeBetrayersModal} title='Unfollowers'>
                          <StyledUpgradeModalContent>
                              <StyledModalTextContainer>
                                This feature is only available to users on the Platinum plan.
                              </StyledModalTextContainer>
                              <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'unfollowers_modal')}>Browse plans</StyledButton>
                          </StyledUpgradeModalContent>
                      </SoundCloudUsersModal>
                      <SoundCloudUsersModal isModalOpen={isOneWayFollowsModalOpen} closeModal={closeOneWayFollowsModal} title='Manual One-way Follows'>
                          {oneWayFollowItems}
                      </SoundCloudUsersModal>
                      <SoundCloudUsersModal isModalOpen={isFollowBackUsersModalOpen} closeModal={closeFollowBackUsersModal} title='Follow-Backs'>
                          <StyledUpgradeModalContent>
                              <StyledModalTextContainer>
                                This feature is only available to users on the Gold or Platinum plans.
                              </StyledModalTextContainer>
                              <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'follow_backs_modal')}>Browse plans</StyledButton>
                          </StyledUpgradeModalContent>
                      </SoundCloudUsersModal>
                      <SoundCloudUsersModal isModalOpen={isNonFollowBackUsersModalOpen} closeModal={closeNonFollowBackUsersModal} title='Pending Follow-Backs'>
                          <StyledUpgradeModalContent>
                              <StyledModalTextContainer>
                                This feature is only available to users on the Gold or Platinum plans.
                              </StyledModalTextContainer>
                              <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'non_follow_backs_modal')}>Browse plans</StyledButton>
                          </StyledUpgradeModalContent>
                      </SoundCloudUsersModal>
                      <SoundCloudUsersModal isModalOpen={isToFollowUsersModalOpen} closeModal={closeToFollowUsersModal} title='Scheduled Follows'>
                          {toFollowUserItems}
                      </SoundCloudUsersModal>
                      <GraphModal isModalOpen={isFollowerCountGraphModalOpen} closeModal={closeFollowerCountGraphModal} title='SoundCloud follower count over time'>
                          <StyledUpgradeModalContent>
                              <StyledModalTextContainer>
                                  <FollowerCountGraph followerCounts={props.followerCounts} />
                              </StyledModalTextContainer>
                          </StyledUpgradeModalContent>
                      </GraphModal>
                  </>
                );
            }
            case 'GOLD_YEARLY':
            case 'GOLD': {
                return (
                    <>
                        <SoundCloudUsersModal isModalOpen={isBetrayersModalOpen} closeModal={closeBetrayersModal} title='Unfollowers'>
                            <StyledUpgradeModalContent>
                                <StyledModalTextContainer>
                                    This feature is only available to users on the Platinum plan.
                                </StyledModalTextContainer>
                                <StyledButton onClick={handleBrowsePlansButtonClick.bind(null, 'unfollowers_modal')}>Browse plans</StyledButton>
                            </StyledUpgradeModalContent>
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isOneWayFollowsModalOpen} closeModal={closeOneWayFollowsModal} title='Manual One-way Follows'>
                            {oneWayFollowItems}
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isFollowBackUsersModalOpen} closeModal={closeFollowBackUsersModal} title='Follow-Backs'>
                            {followBackUserItems}
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isNonFollowBackUsersModalOpen} closeModal={closeNonFollowBackUsersModal} title='Pending Follow-Backs'>
                            {nonFollowBackUserItems}
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isToFollowUsersModalOpen} closeModal={closeToFollowUsersModal} title='Scheduled Follows'>
                            {toFollowUserItems}
                        </SoundCloudUsersModal>
                        <GraphModal isModalOpen={isFollowerCountGraphModalOpen} closeModal={closeFollowerCountGraphModal} title='SoundCloud follower count over time'>
                            <StyledUpgradeModalContent>
                                <StyledModalTextContainer>
                                    <FollowerCountGraph followerCounts={props.followerCounts} />
                                </StyledModalTextContainer>
                            </StyledUpgradeModalContent>
                        </GraphModal>
                    </>
                );
            }
            case 'PLATINUM_YEARLY':
            case 'PLATINUM': {
                return (
                    <>
                        <SoundCloudUsersModal isModalOpen={isBetrayersModalOpen} closeModal={closeBetrayersModal} title='Unfollowers'>
                            {betrayerItems}
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isOneWayFollowsModalOpen} closeModal={closeOneWayFollowsModal} title='Manual One-way Follows'>
                            {oneWayFollowItems}
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isFollowBackUsersModalOpen} closeModal={closeFollowBackUsersModal} title='Follow-Backs'>
                            {followBackUserItems}
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isNonFollowBackUsersModalOpen} closeModal={closeNonFollowBackUsersModal} title='Pending Follow-Backs'>
                            {nonFollowBackUserItems}
                        </SoundCloudUsersModal>
                        <SoundCloudUsersModal isModalOpen={isToFollowUsersModalOpen} closeModal={closeToFollowUsersModal} title='Scheduled Follows'>
                            {toFollowUserItems}
                        </SoundCloudUsersModal>
                        <GraphModal isModalOpen={isFollowerCountGraphModalOpen} closeModal={closeFollowerCountGraphModal} title='SoundCloud follower count over time'>
                            <StyledUpgradeModalContent>
                                <StyledModalTextContainer>
                                    <FollowerCountGraph followerCounts={props.followerCounts} />
                                </StyledModalTextContainer>
                            </StyledUpgradeModalContent>
                        </GraphModal>
                    </>
                );
            }
            default: {
                return <></>;
            }
        }

    };

    const getOneWayFollowsDataField = () => {
        const { oneWayFollows } = props
        if (props.oneWayFollows.loading) {
            return <StyledLoadingBars/>
        }
        return (
          <StyledDataField>
              {oneWayFollows.items.length}
          </StyledDataField>
        )
    }

    const getToFollowUsersDataField = () => {
        if (currentUser.selectedPlan === 'INTRO') {
            return (
              <StyledBlurredDataField>
                  N/A
              </StyledBlurredDataField>
            );
        }
        switch(currentUser.subscription) {
            case 'BASIC': {
                return (
                    <StyledDataField>
                        {props.toFollowUsers.length}
                    </StyledDataField>
                );
            }
            case 'GOLD_YEARLY':
            case 'GOLD': {
                return (
                    <StyledDataField>
                        {props.toFollowUsers.length}
                    </StyledDataField>
                );
            }
            case 'PLATINUM_YEARLY':
            case 'PLATINUM': {
                return (
                    <StyledDataField>
                        {props.toFollowUsers.length}
                    </StyledDataField>
                );
            }
            default: {
                return <></>
            }
        }
    };

    const getFollowBackUsersDataField = () => {
        if (currentUser.selectedPlan === 'INTRO') {
            return (
              <StyledBlurredDataField>
                  N/A
              </StyledBlurredDataField>
            );
        }
        switch(currentUser.subscription) {
            case 'BASIC': {
                return (
                    <StyledBlurredDataField>
                        N/A
                    </StyledBlurredDataField>
                );
            }
            case 'GOLD_YEARLY':
            case 'GOLD': {
                return (
                    <StyledDataField>
                        {props.followBackUsers.length}
                    </StyledDataField>
                );
            }
            case 'PLATINUM_YEARLY':
            case 'PLATINUM': {
                return (
                    <StyledDataField>
                        {props.followBackUsers.length}
                    </StyledDataField>
                );
            }
            default: {
                return <></>
            }
        }
    };

    const getDisabledButtonHint = (): string => {
        if (props.automaticScheduling && currentUser.subscription !== 'BASIC') {
            return `Automatic Scheduling is ON`
        } else {
            return `You can schedule more follows once your number of Scheduled Follows drops below ${getScheduledFollowsLimit()}`
        }
    }

    const getNonFollowBackUsersDataField = () => {
        if (currentUser.selectedPlan === 'INTRO') {
            return (
              <StyledBlurredDataField>
                  N/A
              </StyledBlurredDataField>
            );
        }
        switch(currentUser.subscription) {
            case 'BASIC': {
                return (
                    <StyledBlurredDataField>
                        N/A
                    </StyledBlurredDataField>
                );
            }
            case 'GOLD_YEARLY':
            case 'GOLD': {
                return (
                    <StyledDataField>
                        {props.nonFollowBackUsers.length}
                    </StyledDataField>
                );
            }
            case 'PLATINUM_YEARLY':
            case 'PLATINUM': {
                return (
                    <StyledDataField>
                        {props.nonFollowBackUsers.length}
                    </StyledDataField>
                );
            }
            default: {
                return <></>
            }
        }
    };

    const getBetrayersDataField = () => {
        if (currentUser.selectedPlan === 'INTRO') {
            return (
              <StyledBlurredDataField>
                  N/A
              </StyledBlurredDataField>
            );
        }
        switch(currentUser.subscription) {
            case 'BASIC': {
                return (
                    <StyledBlurredDataField>
                        N/A
                    </StyledBlurredDataField>
                );
            }
            case 'GOLD_YEARLY':
            case 'GOLD': {
                return (
                    <StyledBlurredDataField>
                        N/A
                    </StyledBlurredDataField>
                );
            }
            case 'PLATINUM_YEARLY':
            case 'PLATINUM': {
                return (
                    <StyledDataField>
                        {props.betrayers.length}
                    </StyledDataField>
                );
            }
            default: {

            }
        }
    };

    return (
        <div>
            {generateModals()}
            <NewUserModal isModalOpen={isInfoModalOpen} closeModal={closeInfoModal} title='Welcome to Artist Management!'>
                <StyledInfoModalContent>
                    <StyledInfoModalTextHeader>
                        We're here to help you grow your fanbase on SoundCloud. Here are some tips to get started:
                    </StyledInfoModalTextHeader>
                    <ul>
                        <li>
                            Go to SoundCloud and find an artist or a track with music similar to yours. You can
                            find up and coming artists in your genre whose tracks are growing in popularity or you
                            can also simply find the most popular artists or tracks in your genre.
                        </li>
                        <li>
                            Paste the artist URL or track URL in the input field and click/tap submit. Our algorithms
                            will then collect all related active SoundCloud users who don’t already
                            follow you and whom you don’t follow. These active users will then appear in your
                            Scheduled Follows list. Repeat this process to accumulate as many Scheduled Follows as
                            your plan will allow.
                        </li>
                    </ul>
                    <StyledInfoModalTextHeader>
                        How it works:
                    </StyledInfoModalTextHeader>
                    <ul>
                        <li>
                            Everyday, Artist Management will follow 40 of the active SoundCloud users from this list. If the
                            user is also an artist, Artist Management will like their most recent track on your behalf. This
                            is to increase the chance the the SoundCloud user will give your music a listen.
                            Once the user has been followed, they will be removed from the Scheduled Follows list
                            and will appear in the Pending Follow-Backs list. As the number of scheduled follows drops
                            below your plan limit, you will be eligible to schedule more follows. Once SoundCloud users
                            follow you back, they will be removed from the Pending Follow-Backs list and will appear
                            in the Follow-Backs list.
                        </li>
                        <li>
                            Within a certain number of days that is determined by your plan, Artist Management will begin
                            to unfollow 40 users that it previously followed each day and will unlike any track that it
                            previously liked on your behalf. For all subscription, Artist Management
                            begins unfollowing accounts and unliking their tracks on a rolling 30-day window. For Platinum subscribers,
                            Artist Management unfollows and unlikes the tracks of all SoundCloud users that it
                            followed, regardless of whether the user followed you back. For Basic and Gold subscribers,
                            Artist Management unfollows and unlikes tracks of only the users who
                            haven’t follow you back.
                        </li>
                        <li>
                            If you have a Platinum subscription you can also see the list of SoundCloud users who
                            unfollowed you by clicking the Unfollowers list.
                        </li>
                        <li>
                            All plans have access to the Manual One-way Follows list. This is a list of SoundCloud users
                            whom you have manually followed, but who never followed you back. You can choose to have Artist
                            Management automatically unfollow these users by setting the "Unfollow Manual One-way Follows"
                            switch to the ON position.
                        </li>
                        <li>
                            All plans also have access to the Follower Count graph. This feature plots your SoundCloud
                            follower count over the last 30 days so that you can monitor your follower growth over time.
                        </li>
                    </ul>
                    <StyledInfoModalTextFooter>
                        If you have questions about your account or need further assistance, contact support@artistmgmt.org
                    </StyledInfoModalTextFooter>
                </StyledInfoModalContent>
            </NewUserModal>
            <StyledAnalyticsDashboardHeader>
                <StyledProfileHeaderRow>
                    <StyledProfilePictureContainer>
                        <StyledProfilePictureMask onClick={navigateToSoundCloudProfile}>
                            <UserProfilePicture src={currentUser.scPhotoUrl} alt=''/>
                        </StyledProfilePictureMask>
                    </StyledProfilePictureContainer>
                    {scoreSection()}
                </StyledProfileHeaderRow>
                <StyledToggleContainer>
                    <AMToggle
                      tooltipTitle={['GOLD', 'GOLD_YEARLY', 'PLATINUM', 'PLATINUM_YEARLY'].includes(currentUser.subscription ?? 'INTRO') ?
                        'When this switch is ON, Artist Management will automatically schedule new follows each day' :
                        'This feature is only available to users on the GOLD and PLATINUM plans'
                      }
                      isOn={['GOLD', 'GOLD_YEARLY', 'PLATINUM', 'PLATINUM_YEARLY'].includes(currentUser.subscription ?? 'INTRO') ? props.automaticScheduling : false}
                      label='Automatic Scheduling'
                      disabled={!['GOLD', 'GOLD_YEARLY', 'PLATINUM', 'PLATINUM_YEARLY'].includes(currentUser.subscription ?? 'INTRO')}
                      onToggle={handleAutomaticSchedulingSwitchFlip}
                    />
                    <AMToggle
                      tooltipTitle={currentUser.selectedPlan === 'INTRO' ?
                        'This feature is only available to users on a paid plan' :
                        'When this switch is ON, Artist Management will automatically follow accounts from your Scheduled Follows list'
                      }
                      isOn={currentUser.selectedPlan === 'INTRO' ? false : props.isFollowingEnabled}
                      label={props.isFollowingEnabled ? 'Following Enabled' : 'Following Disabled'}
                      onToggle={handleFollowingSwitchFlip} disabled={currentUser.selectedPlan === 'INTRO'}
                    />
                    <AMToggle
                      tooltipTitle={currentUser.selectedPlan === 'INTRO' ?
                        'This feature is only available to users on a paid plan' :
                        'When this switch is ON, Artist Management will automatically unfollow accounts that you have followed manually, but who have never followed you back (Manual One-way Follows)'}
                      isOn={currentUser.selectedPlan === 'INTRO' ? false : props.unfollowOneWayFollows}
                      label='Unfollow Manual One-way Follows' onToggle={handleUnfollowOneWayFollowsSwitchFlip}
                      disabled={currentUser.selectedPlan === 'INTRO'}
                    />
                </StyledToggleContainer>
            </StyledAnalyticsDashboardHeader>
            <StyledAnalyticsSection>
                <StyledStatsSection>
                    <StyledStatsRow>
                        <Tooltip title='Click here to see the SoundCloud accounts that you are scheduled to follow automatically' arrow>
                            <StyledStatsItem onClick={openToFollowUsersModal}>
                                {getToFollowUsersDataField()}
                                <StyledMiniButton>
                                    Scheduled Follows
                                </StyledMiniButton>
                            </StyledStatsItem>
                        </Tooltip>
                        <Tooltip title='Click here to see the SoundCloud followers that you have gained as a result of using Artist Management' arrow>
                            <StyledStatsItem onClick={openFollowBackUsersModal}>
                                {getFollowBackUsersDataField()}
                                <StyledMiniButton>
                                    Follow-Backs
                                </StyledMiniButton>
                            </StyledStatsItem>
                        </Tooltip>
                        <Tooltip title='Click here to see the SoundCloud accounts that were followed automatically and have yet to follow you back' arrow>
                            <StyledStatsItem onClick={openNonFollowBackUsersModal}>
                                {getNonFollowBackUsersDataField()}
                                <StyledMiniButton>
                                    Pending Follow-Backs
                                </StyledMiniButton>
                            </StyledStatsItem>
                        </Tooltip>
                        <Tooltip title='Click here to see the SoundCloud accounts that have unfollowed you' arrow>
                            <StyledStatsItem onClick={openBetrayersModal}>
                                {getBetrayersDataField()}
                                <StyledMiniButton>
                                    Unfollowers
                                </StyledMiniButton>
                            </StyledStatsItem>
                        </Tooltip>
                        <Tooltip title='Click here to see the SoundCloud accounts that you followed manually, but who never followed you back' arrow>
                            <StyledStatsItem onClick={openOneWayFollowsModal}>
                                {getOneWayFollowsDataField()}
                                <StyledMiniButton>
                                    Manual One-way Follows
                                </StyledMiniButton>
                            </StyledStatsItem>
                        </Tooltip>
                    </StyledStatsRow>
                </StyledStatsSection>
            </StyledAnalyticsSection>

            <StyledGraphButtonRow>
            <Tooltip title='Click here to view a graph of your SoundCloud follower count over time' arrow>
                <StyledGraphButton
                onClick={openFollowerCountGraphModal}
                $isDisabled={props.followerCounts.length < 7}
                >
                  Follower Count <BsGraphUpArrow/>
                </StyledGraphButton>
            </Tooltip>
            </StyledGraphButtonRow>
            <SourceFollowersForm
              onInfoIconClick={openInfoModal}
              isDisabled={isScheduleFollowersFormDisabled()}
              disabledButtonText={getScheduledFollowsDisabledButtonText()}
            />
            {isScheduleFollowersFormDisabled() && (
              <StyledComeBackSoonHint>
                <span>
                    {getDisabledButtonHint()}
                </span>
              </StyledComeBackSoonHint>
            )}
            {sources.length > 0 && currentUser.selectedPlan !== 'INTRO' &&
              <StyledRecommendedSourcesContainer>
                <StyledRecommendedSourcesTitle>Previous URLs used for scheduling follows:</StyledRecommendedSourcesTitle>
                <StyledRecommendedSources>
                { sources.map((source: string, index: number) => (
                    <RecommendedTrack key={`source_${index}`} source={source}/>
                ))}
              </StyledRecommendedSources>
              </StyledRecommendedSourcesContainer>
            }
        </div>

    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.loading.isLoading,
        currentUser: state.auth.currentUser,
        toFollowUsers: state.analytics.toFollowUsers,
        followBackUsers: state.analytics.followBackUsers,
        nonFollowBackUsers: state.analytics.nonFollowBackUsers,
        followerCounts: state.analytics.followerCounts,
        betrayers: state.analytics.betrayers,
        oneWayFollows: state.analytics.oneWayFollows,
        recommendedSources: state.analytics.recommendedSources,
        juicySources: state.analytics.juicySources,
        isFollowingEnabled: state.analytics.isFollowingEnabled,
        unfollowOneWayFollows: state.analytics.unfollowOneWayFollows,
        automaticScheduling: state.analytics.automaticScheduling,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        setIsFollowingEnabled,
        setUnfollowOneWayFollows,
        setAutomaticScheduling,
        restartOnboarding,
        refreshSoundCloudUserData,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AnalyticsDashboard);
