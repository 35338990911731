import {FC} from 'react'
import styled from 'styled-components'
import { FcMoneyTransfer } from "react-icons/fc";
import {getFormattedCurrency} from '../../helpers/Utils'
import media from 'styled-media-query'


const Container = styled.div`
    flex-basis: 35%;
    background-color: ${props => props.theme.colors.white};
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    margin: 24px;
    padding: 24px;
    ${media.greaterThan("medium")`
      padding: 24px 48px;
      margin: 0
    `}
`

const Heading = styled.h2`
  margin: 0 0 -4px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  display: flex;
  align-items: center;
  column-gap: 12px;
  white-space: nowrap;
`;

const NextPayout = styled.div`
    flex-direction: column;
    margin-top: 40px
`

const Subtitle = styled.div`
`
const DollarAmount = styled.div`
  font-size: 3em;
`

type PayoutBalanceCardProps = {
  currentBalance: number,
}

const PayoutBalanceCard: FC<PayoutBalanceCardProps> = ({ currentBalance }) => {
  return (
    <Container>
      <Heading>
        <FcMoneyTransfer/>
        Payout Balance
      </Heading>
      <NextPayout>
        <Subtitle>
          Total amount due to you
        </Subtitle>
        <DollarAmount>
          {getFormattedCurrency('en', 'USD', currentBalance)}
        </DollarAmount>
      </NextPayout>
    </Container>
  )
}

export default PayoutBalanceCard