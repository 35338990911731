import React, { useRef, useState, RefObject, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { defaultBreakpoints } from "styled-media-query";
import {AuthState, RootState} from './types/States';
import {bindActionCreators, compose, Dispatch} from 'redux';
import { connect } from 'react-redux';
import media from "styled-media-query";
import Button from './Button';
import ProgressStepIndicator from './components/ProgressStepIndicator';
import { logEvent } from 'firebase/analytics';
import { analytics } from './common/firebase';
import {
  submitFeedbackAndCancelSubscription,
  submitFeedbackAndKeepSubscription,
} from './actions'


const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.2em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
  ${media.greaterThan("medium")`
    font-size: 1.5em;
  `}
`;

const StyledPrimaryButton = styled(Button)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
  font-size: 16px;
  ${media.greaterThan("medium")`
    font-size: 1.0em;
  `}
`;

const StyledSecondaryButton = styled(Button)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
  background-color: #EDEEF1;
  color: ${props => props.theme.colors.black};
  border-color: #ADB1B9;
  font-size: 16px;
  ${media.greaterThan("medium")`
    font-size: 1.0em;
  `}
`;

const StyledByline = styled.div`
    font-size: 0.8em;
    line-height: 1.5em;
    text-align: center;
    ${media.greaterThan("medium")`
        font-size: 1.0em;
    `}
`;

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  margin: auto;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 300px;
    margin: 12px auto 0;
    font-size: 1.1em;
    border: 1px ${props => props.theme.colors.gray} solid;
`;

const StyledCancellationFlowContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    ${media.greaterThan("medium")`
      max-width: 750px;
      margin: auto;
    `}
`;

const StyledConfirmCancellationContainer = styled(StyledCancellationFlowContainer)`
    height: 55vh;
    ${media.greaterThan("medium")`
        height: unset;
    `}
    
`;

const StyledOptOutLinkContainer = styled(StyledByline)`
    cursor: pointer;
    text-decoration: underline;
    font-size: 0.7em;
    ${media.greaterThan("medium")`
      order: 2;
      font-size: 0.8em;
    `}
`;

const StyledButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    background-color: #FBF6E9;
    padding-top: 10px;
    width: 100%;
    box-shadow: 0 -5px 5px -7px #333;
    ${media.greaterThan("medium")`
      order: 1;
      margin-top: 20px;
      position: relative;
      bottom: unset;
      background-color: unset;
      padding-top: 0px;
      box-shadow: none;
    `}
`;

type CancellationFlowProps = {
  currentUser: AuthState["currentUser"],
  submitFeedbackAndCancelSubscription: typeof submitFeedbackAndCancelSubscription,
  submitFeedbackAndKeepSubscription: typeof submitFeedbackAndKeepSubscription,
}

const CancellationFlow: React.FC<CancellationFlowProps> = (props: CancellationFlowProps) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [feedback, setFeedback] = useState('');
    const textAreaEl: RefObject<HTMLTextAreaElement> = useRef(null);
    const isDesktop = useMediaQuery({
        minWidth: defaultBreakpoints.medium
    });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [currentStep]);
    const history = useHistory();

    const { currentUser, submitFeedbackAndKeepSubscription, submitFeedbackAndCancelSubscription } = props

    if (!currentUser) {
      return null
    }

    const captureFeedback = () => {
        if (!textAreaEl.current) {
            return;
        }
        setFeedback(textAreaEl.current.value);
        logEvent(analytics, 'ff_button_click', {
            name: `submit_feedback`
        });
        setCurrentStep(2);
    };

    const skipToStepTwo = () => {
        logEvent(analytics, 'ff_button_click', {
            name: `skip_submit_feedback_link`
        });
        setCurrentStep(2);
    };

    const keepSubscription = () => {
        const fb = textAreaEl.current?.value ?? ''
        submitFeedbackAndKeepSubscription(currentUser, fb, () => {
            history.push('/dashboard');
        });
        logEvent(analytics, 'ff_button_click', {
            name: `keep_subscription`
        });
    };

    const cancelSubscription = () => {
        submitFeedbackAndCancelSubscription(currentUser, feedback, () => {
           history.push('/dashboard');
        });
        logEvent(analytics, 'ff_button_click', {
            name: `confirm_cancellation`
        });
    };

    const renderBody = () => {
        if (currentStep === 1) {
            logEvent(analytics, 'ff_page_view', {
                page_title: 'cancellation_submit_feedback'
            });

            return (
                <StyledCancellationFlowContainer>
                    <StyledHeading>Please share your feedback</StyledHeading>
                    <StyledByline>We value your feedback. Let us know why you are cancelling your subscription and how we can improve Artist Management.</StyledByline>
                    <StyledTextArea placeholder="Enter feedback" ref={textAreaEl}/>
                    <StyledOptOutLinkContainer onClick={skipToStepTwo}>No, I don't want to provide feedback. Please cancel my subscription.</StyledOptOutLinkContainer>
                    <StyledButtonContainer>
                        <StyledSecondaryButton onClick={captureFeedback}>{isDesktop ? 'Submit feedback and cancel subscription' : 'Submit and cancel'}</StyledSecondaryButton>
                        <StyledPrimaryButton onClick={keepSubscription}>Keep my subscription</StyledPrimaryButton>
                    </StyledButtonContainer>
                </StyledCancellationFlowContainer>
            )

        } else {
            logEvent(analytics, 'ff_page_view', {
                page_title: 'cancellation_confirm'
            });
            const endDateExpression = (currentUser.subscriptionCycleEnd && currentUser.subscriptionCycleEnd.toDate().toDateString()) ?? 'the end of the current billing cycle'

            return (
              <StyledConfirmCancellationContainer>
                  <StyledHeading>Confirm subscription cancellation</StyledHeading>
                  <StyledByline>
                    We're sorry to see you go. You will continue to enjoy all the benefits of your Artist Management plan until <em>{endDateExpression}</em>, after which you will no longer be billed.
                  </StyledByline>
                  <StyledButtonContainer>
                      <StyledPrimaryButton onClick={keepSubscription}>Keep My Subscription</StyledPrimaryButton>
                      <StyledSecondaryButton onClick={cancelSubscription}>Confirm cancellation</StyledSecondaryButton>
                  </StyledButtonContainer>
              </StyledConfirmCancellationContainer>
            )
        }
    };

    return (
        <StyledContainer>
            <StyledContent>
                <StyledDiv>
                    <ProgressStepIndicator numSteps={2} currentStep={currentStep as 1 | 2 | 3}/>
                    {renderBody()}
                </StyledDiv>
            </StyledContent>
        </StyledContainer>
    );
};
function mapStateToProps(state: RootState) {
    return {
        currentUser: state.auth.currentUser,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    submitFeedbackAndCancelSubscription,
    submitFeedbackAndKeepSubscription,
  }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(CancellationFlow);
