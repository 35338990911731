import { combineReducers } from 'redux';
import auth from './auth';
import loading from './loading';
import flash from './flash';
import analytics from './analytics';
import referral from './referral';

export default combineReducers({
    auth,
    loading,
    flash,
    analytics,
    referral,
});