import { Referral } from '../../types/Referral'
import styled from 'styled-components'
import { FC } from 'react'
import PayoutBalanceCard from './PayoutBalanceCard'
import PayoutEarningsCard from './PayoutEarningsCard'
import media from 'styled-media-query'
import {Timestamp} from 'firebase/firestore'


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    margin-top: 24px;
    ${media.greaterThan("medium")`
      padding: 24px;
      flex-direction: row;
    `}
`

type PayoutHeaderProps = {
  referrals: Referral[],
  referrerScUsername: string,
  promoterInitiatedAt: Timestamp,
}

const PayoutHeader: FC<PayoutHeaderProps> = ({ referrals, referrerScUsername, promoterInitiatedAt }) => {
  const unpaidBalance = referrals.reduce((total, referral) => {
    if (!referral.paidOutAt) {
      return total + referral.payoutAmountUSD
    }
    return total
  }, 0)

  const totalEarnings =  referrals.reduce((total, referral) => {
    if (referral.paidOutAt) {
      return total + referral.payoutAmountUSD
    }
    return total
  }, 0)

  return (
    <StyledContainer>
      <PayoutEarningsCard totalEarnings={totalEarnings} promoterInitiatedAt={promoterInitiatedAt} scUsername={referrerScUsername}/>
      <PayoutBalanceCard currentBalance={unpaidBalance}/>
    </StyledContainer>
  )
}

export default PayoutHeader
