import {FC, useState} from 'react'
import styled from 'styled-components'
import { FcMoneyTransfer } from "react-icons/fc";
import {Timestamp} from 'firebase/firestore'
import {getFormattedCurrency} from '../../helpers/Utils'
import copy from 'copy-to-clipboard'
import media from 'styled-media-query'



const Container = styled.div`
    flex-basis: 65%;
    background-color: ${props => props.theme.colors.white};
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    margin: 24px;
    padding: 24px;
    ${media.greaterThan("medium")`
      padding: 24px 48px;
      margin: 0
    `}
`

const Heading = styled.h2`
  margin: 0 0 -4px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const Subtitle = styled.div`
`

const DollarAmount = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const ReferralLink = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`

const ReferralLinkBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
`

const LifetimeEarnings = styled.div`
    margin-top: 12px;
`

const PromoUrl = styled.div`
  font-size: 0.65em;
  border: 1px solid black;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
`
const CopyButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.orange};
  padding: 0 12px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  font-family: Gibson;
  font-weight: 600;
  letter-spacing: 2px;
  width: 80px;

`

type PayoutEarningsCardProps = {
  promoterInitiatedAt: Timestamp,
  scUsername: string,
  totalEarnings: number,
}

const PayoutEarningsCard: FC<PayoutEarningsCardProps> = ({ promoterInitiatedAt, scUsername, totalEarnings}) => {
  const [isCopyClicked, setIsCopyClicked] = useState(false)
  const referralUrl = `https://artistmgmt.org?promoCode=${scUsername}`

  const handleCopyClicked = (source: string) => {
    copy(source)
    setIsCopyClicked(true)
    setTimeout(() => {
      setIsCopyClicked(false)
    }, 1000)
  }

  return (
    <Container>
      <Heading>
        Payout Earnings
      </Heading>
      <Subtitle>
        Your payouts stats since {promoterInitiatedAt.toDate().getFullYear()}
      </Subtitle>
      <LifetimeEarnings>
        <Subtitle>
          Lifetime Earnings
        </Subtitle>
        <DollarAmount>
          <FcMoneyTransfer/>
          {getFormattedCurrency('en', 'USD', totalEarnings)} from referral payouts
        </DollarAmount>
      </LifetimeEarnings>
      <ReferralLink>
        <Subtitle>
          Earn up to $17.50 per referral
        </Subtitle>
        <ReferralLinkBox>
          <PromoUrl>{referralUrl}</PromoUrl>
          <CopyButton onClick={handleCopyClicked.bind(null, referralUrl)}>{isCopyClicked ? 'Copied!' : 'Copy' }</CopyButton>
        </ReferralLinkBox>
      </ReferralLink>
    </Container>
  )
}

export default PayoutEarningsCard